<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsinstructor></layoutsinstructor>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <instructorsidebar></instructorsidebar>

          <!-- Profile Details -->
          <div class="col-xl-9 col-md-8">
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>Social Profiles</h3>
                  <p>Add your social profile links in below social accounts.</p>
                </div>
                <div class="checkout-form personal-address">
                  <form action="#">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">Twitter</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Twitter Profile Name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">Facebook</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Facebook Profile Name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">Instagram</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Instagram Profile Name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">LinkedIn Profile URL</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="LinkedIn Profile URL"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">YouTube</label>
                          <input type="text" class="form-control" placeholder="YouTube" />
                        </div>
                      </div>
                      <div class="update-profile save-social">
                        <button type="button" class="btn btn-primary">
                          Save Social Profile
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile Details -->
        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <div v-if="!isLoggedIn" class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-8">
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3 style="color: orange;">{{ $t('warningTitle') }}</h3>
                  <h6>{{ $t('notAuthorizedMessage') }}</h6>
                  <h6>{{ $t('loginOrSignupMessage') }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Dashboard Student -->
    <div v-else class="page-content">
      <div class="container">
        <div class="row">
          <studentsidebar :fullName="fullName" :profilePictureUrl="profilePictureUrl"></studentsidebar>

          <!-- Profile Details -->
          <div class="col-xl-9 col-md-8">
            <b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.message }}</b-alert>

            <b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.message }}</b-alert>
            <div v-if="uploading" class="progress-bar-container">
              <div :style="{ 'width': progress + '%' }" class="progress-bar"></div>
            </div>

            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>{{ $t('profileDetailsTitle') }}</h3>
                  <p>{{ $t('profileDetailsSubtitle') }}</p>
                </div>
                <div class="course-group mb-0 d-flex">
                  <div class="course-group-img d-flex align-items-center">
                    <img v-if="selectedImage" :src="selectedImage" alt="" class="img-fluid" />
                    <div class="course-name">
                      <h4>{{ $t('profilePictureTitle') }}</h4>
                      <p>{{ $t('profilePictureSubtitle') }}</p>
                      <label class="custom-file-upload">
                        {{ $t('selectPictureLabel') }}
                        <input type="file" accept=".png, .jpg, .jpeg" @change="handleFileChange" :disabled="uploading">
                      </label>
                      <p v-if="!isValid && showMessage" style="color: orangered;">{{ $t('imageInvalidMessage') }}</p>
                    </div>
                  </div>
                </div>
                <div class="course-group mb-0 d-flex">
                  <div class="profile-share-new">
                    <button @click="uploadProfilePicture" :disabled="!selectedImage || uploading"
                      class="btn btn-success">{{ $t('updateButton') }}</button>
                    <button @click="showDeleteConfirmation" class="btn btn-danger" :disabled="uploading">{{ $t('deleteButton') }}</button>
                    <div v-if="showModal" class="modal-content">
                      <p><i class="fas fa-exclamation-triangle" style="font-size: 32px; color: orangered;"></i></p>
                      <div style="line-height: 0%;">
                        <p>{{ $t('deleteConfirmationMessage') }}</p>
                        <p>{{ $t('deleteConfirmationSubtitle') }}</p>
                      </div>
                      <div class="profile-share-new">
                        <button @click="confirmDelete" class="btn btn-success" :disabled="uploading">{{ $t('yesButton') }}</button>
                        <button @click="cancelDelete" class="btn btn-cancel" :disabled="uploading">{{ $t('noButton') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-form personal-address add-course-info">
                  <div class="personal-info-head">
                    <h4>{{ $t('personalDetailsTitle') }}</h4>
                    <p>{{ $t('personalDetailsSubtitle') }}</p>
                  </div>
                  <form @submit.prevent="handleSubmit" class="register">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">{{ $t('nameLabel') }} <span class="text-danger">*</span></label>
                          <input v-model="name" type="text" class="form-control" :placeholder="$t('namePlaceholder')"
                            :class="{ 'is-invalid': submitted && v$.name.$error }" @blur="v$.name.$touch" />
                          <div v-for="error of v$.name.$errors" :key="error.$uid">
                            <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">{{ $t('fathersNameLabel') }} <span class="text-danger">*</span></label>
                          <input v-model="fathersName" type="text" class="form-control" :placeholder="$t('fathersNamePlaceholder')"
                            :class="{ 'is-invalid': submitted && v$.fathersName.$error }" @blur="v$.fathersName.$touch" />
                          <div v-for="error of v$.fathersName.$errors" :key="error.$uid">
                            <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">{{ $t('grandFathersNameLabel') }} <span class="text-danger">*</span></label>
                          <input v-model="grandFathersName" type="text" class="form-control" :placeholder="$t('grandFathersNamePlaceholder')"
                            :class="{ 'is-invalid': submitted && v$.grandFathersName.$error }" @blur="v$.grandFathersName.$touch" />
                          <div v-for="error of v$.grandFathersName.$errors" :key="error.$uid">
                            <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- Sex and Age Range fields side by side -->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">{{ $t('sexLabel') }}</label>
                          <select v-model="sex" class="form-control">
                    <option value="ሴት / Female">ሴት / Female</option>
                    <option value="ወንድ / Male">ወንድ / Male</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">{{ $t('ageRangeLabel') }}</label>
                          <select v-model="ageRange" class="form-control">
                            <option value="Below 18">Below 18</option>
                            <option value="18-25">18-25</option>
                            <option value="26-35">26-35</option>
                            <option value="36-45">36-45</option>
                            <option value="46-55">46-55</option>
                            <option value="56-65">56-65</option>
                            <option value="66+">66+</option>
                          </select>
                        </div>
                      </div>

                      <!-- Note for default values -->
                      <div class="col-lg-12" v-if="isDefaultValueChosen">
                        <p class="text-warning">
                          Note: The values for sex and age range were null and have been set to default values.
                        </p>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group custom-select">
                          <label class="form-control-label">{{ $t('regionLabel') }}</label>
                          <div class="form-group">
                            <select id="selectedCountry" v-model="selectedCountry">
                              <option value="" disabled>{{ $t('regionPlaceholder') }}</option>
                              <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="update-profile mt-5">
                        <button type="submit" :disabled="uploading" class="btn btn-primary">
                          {{ $t('updateProfileButton') }}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile Details -->
        </div>
      </div>
    </div>
    <!-- /Dashboard Student -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import axios from "axios";
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
  data() {
    return {
      selectedCountry: '',
      countries: [
        { code: '1', name: 'Addis Abeba' },
        { code: '2', name: 'Afar' },
        { code: '3', name: 'Amhara' },
        { code: '4', name: 'Benishangul-Gumuz' },
        { code: '5', name: 'Central Ethiopia' },
        { code: '6', name: 'Dire Dawa' },
        { code: '7', name: 'Gambela' },
        { code: '8', name: 'Harari' },
        { code: '9', name: 'Oromia' },
        { code: '10', name: 'Sidama' },
        { code: '11', name: 'Somali' },
        { code: '12', name: 'South Ethiopia' },
        { code: '13', name: 'South West Ethiopia' },
        { code: '14', name: 'Tigray' },
      ],
      isLoggedIn: false,
      firstName: '',
      fatherName: '',
      selectedImage: null,
      apiResponse: null,

      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
      isLoading: false,
      error: '',

      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      submit: false,
      submitted: false,
      formsubmit: false,
      typesubmit: false,

      showSuccessDialog: false,

      message: "",
      studentId: "",
      uploading: false,

      name: '',
      fathersName: '',
      grandFathersName: '',

      fullName: '',
      profilePictureUrl: '',
      user: null,
      region: '',

      showModal: false,
      progress: 0,

      isValid: false,
      showMessage: false,

      sex: "ሴት / Female", // Default value
      ageRange: "26-35", // Default value
      isDefaultValueChosen: false // Flag to check if default values were chosen
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      name: { required, minLength: minLength(2) },
      fathersName: { required, minLength: minLength(2) },
      grandFathersName: { required, minLength: minLength(2) },
    };
  },
  mounted() {
    this.storedPhoneNumber = localStorage.getItem("phoneNumber");
    this.storedStudentId = localStorage.getItem("studentId");
    if (this.storedPhoneNumber)
      if (this.storedPhoneNumber.length > 0)
        this.isLoggedIn = true;

    const userData = localStorage.getItem('userData');

    if (userData) {
      this.user = JSON.parse(userData);

      this.fullName = this.user.fullName;
      if (this.user.name)
        this.name = this.user.name
      if (this.user.fathersName)
        this.fathersName = this.user.fathersName
      if (this.user.grandFathersName)
        this.grandFathersName = this.user.grandFathersName

      if (this.user.profilePicture) {
        if (this.user.profilePicture.length > 0)
          this.profilePictureUrl = this.user.profilePicture;
        else
          this.profilePictureUrl = 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e'
      }
      else
        this.profilePictureUrl = 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e'

      if (this.user.region)
        this.selectedCountry = this.user.region;

      if (!this.user.sex) {
        this.isDefaultValueChosen = true;
      } else {
        this.sex = this.user.sex;
      }

      if (!this.user.ageRange) {
        this.isDefaultValueChosen = true;
      } else {
        this.ageRange = this.user.ageRange;
      }
    }
    if (this.name.length == 0)
      this.splitName()

    this.progress = 0;
    this.scrollToTop()
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;
      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    async handleSubmit(e) {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return
      } else {
        this.isDefaultValueChosen=true;
        this.updateProfile()
      }
    },
    generateRandomNumber() {
      return Math.floor(Math.random() * 50);
    },
    showMessageDialog() {
      this.progress = 100;
      setTimeout(() => {
        this.uploading = false;
        this.showSuccessDialog = !this.isRegisterError
      }, 1000);
    },
    showDeleteConfirmation() {
      this.showModal = true;
    },
    async confirmDelete() {
      this.uploading = true;
      this.progress = this.generateRandomNumber();
      this.scrollToTop();
      try {
        await axios.post('/api/users/' + this.storedStudentId + '/delete-profile-picture',
          {
            onUploadProgress: (progressEvent) => {
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
            onDownloadProgress: (progressEvent) => {
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
          }).then((response) => {
            this.isLoading = false;
            this.message = response.data.message;
            this.isRegisterError = response.data.error;
            this.tryingToRegister = false;
            this.showMessageDialog();

            localStorage.setItem('userData', JSON.stringify(response.data));
          })

      } catch (error) {
        this.message = 'Profile picture deletion failed. Please try again.';
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
        this.uploading = false;
      }
      this.showModal = false;
    },
    cancelDelete() {
      this.showModal = false;
    },
    splitName() {
      const names = this.fullName.split(' ');
      this.name = names[0] || '';
      this.fathersName = names.slice(1, -1).join(' ').trim() || '';
      this.grandFathersName = names[names.length - 1] || '';
      if (this.fathersName.length == 0) {
        this.fathersName = this.grandFathersName;
        this.grandFathersName = '';
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (allowedTypes.includes(file.type)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
              if (img.width <= 800 && img.height <= 800 && file.size <= 200 * 1024) {
                this.showMessage = false;
                this.isValid = true;
                this.selectedImage = reader.result;
              } else {
                this.showMessage = true;
                this.isValid = false;
              }
            };
          };
          reader.readAsDataURL(file);
        } else {
          this.showMessage = true;
          this.isValid = false;
        }
      }
    },
    async uploadProfilePicture() {
      this.uploading = true;
      this.progress = this.generateRandomNumber();
      this.scrollToTop();
      const blob = await this.dataURItoBlob(this.selectedImage);

      const formData = new FormData();
      formData.append('profilePicture', blob, 'profilePicture.png');

      try {
        await axios.post('/api/users/' + this.storedStudentId + '/upload', formData,
          {
            onUploadProgress: (progressEvent) => {
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
            onDownloadProgress: (progressEvent) => {
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
          }
        ).then((response) => {
          this.apiResponse = response.data.message;
          this.isLoading = false;
          this.message = response.data.message;
          this.studentId = response.data.studentId;
          this.verified = response.data.verified;
          this.isRegisterError = response.data.error;
          this.tryingToRegister = false;
          this.showMessageDialog();
          this.selectedImage = null;

          localStorage.setItem('userData', JSON.stringify(response.data));
        })

      } catch (error) {
        this.message = 'Profile picture upload failed. Please try again.';
        this.uploading = false;
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: mimeString });
    },
    async updateProfile() {
      this.uploading = true;
      this.progress = this.generateRandomNumber();
      this.scrollToTop();
      try {
        await axios.put('/api/users/' + this.storedStudentId + '/update-user-details', {
          name: this.name,
          fathersName: this.fathersName,
          grandFathersName: this.grandFathersName,
          sex: this.sex,
          ageRange: this.ageRange,
          region: this.selectedCountry,
          onUploadProgress: (progressEvent) => {
            this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
          },
          onDownloadProgress: (progressEvent) => {
            this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
          },
        }).then((response) => {
          this.isLoading = false;
          this.message = response.data.message;
          this.isRegisterError = response.data.error;
          this.tryingToRegister = false;
          this.showMessageDialog();

          localStorage.setItem('userData', JSON.stringify(response.data));
        })

      } catch (error) {
        this.message = 'Error updating profile. Please try again.';
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
        this.uploading = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  }
};
</script>
<style>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-from {
  transform: translateY(50px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 1;
} */
.custom-select {
  min-width: 350px;
  position: relative;
}

select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  font-size: 14px;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 0.3rem;
  color: #000;
  cursor: pointer;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 60%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 75%;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  background: transparent;
  color: #000000;
  border: 1px solid #000000;
  margin-right: 10px;
  margin-top: 20px;
  border-radius: 0;
  padding: 7px 22px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.custom-file-upload:hover {
  color: #FFFFFF;
  background-color: #2574F3;
  border: 1px solid #2574F3;
}

/* Style the modal to make it visually appealing */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.modal[style*="max-height:"] {
  max-height: 1000px;
}

.scale-enter-active,
.scale-leave-active {
  transition: transform 0.5s;
  /* Use transform for smooth scaling effect */
}

.scale-enter,
.scale-leave-to {
  transform: scaleY(0);
  transform-origin: top;
  /* Set the origin to the top for vertical scaling */
}

.course-group .profile-share-new .modal-content {
  background: transparent;
  color: #666666;
  border: 1px solid orangered;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 0;
  padding: 10px 10px;
  font-weight: 100;
  border-radius: 5px;
  font-size: 12px;
  transition: transform 330ms ease-in-out;
}

.course-group .profile-share-new .modal-content .btn-success {
  background: transparent;
  color: #666666;
  border: 1px solid #666666;
  margin-right: 10px;
  border-radius: 0;
  padding: 7px 22px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.btn-file-upload {
  background: transparent;
  color: #666666;
  border: 1px solid #666666;
  margin-right: 10px;
  border-radius: 0;
  padding: 7px 22px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.course-group .profile-share-new .modal-content .btn-success:hover {
  background: orangered;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.course-group .profile-share-new .modal-content .btn-cancel {
  background: transparent;
  color: #666666;
  border: 1px solid #666666;
  margin-right: 10px;
  border-radius: 0;
  padding: 7px 22px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.course-group .profile-share-new .modal-content .btn-cancel:hover {
  background: #666666;
  color: #FFFFFF;
  border: 1px solid #666666;
}

/* Style for the progress bar container */
.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #eee;
  border-radius: 3px;
  margin-bottom: 10px;
  overflow: hidden;
}

/* Style for the progress bar */
.progress-bar-container .progress-bar {
  height: 100%;
  background-color: #4caf50;
  /* Green color */
  transition: width 0.3s ease;
  /* Add a smooth transition effect */
  border-radius: 3px;
}
</style>
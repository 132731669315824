<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <logo-with-language />
            <h1>{{ $t('resetPasswordTitle') }}</h1>
            <div class="reset-password">
              <p>{{ $t('resetPasswordInstruction2') }}</p>
            </div>
            <b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.message }}</b-alert>

            <b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.message }}</b-alert>
            <form @submit.prevent="handleSubmit" class="signin">
              <div class="form-group">
                <label for="password">{{ $t('newPasswordLabel') }}
                  <span class="text-danger">{{ $t('requiredAsterisk1') }}</span>
                </label>
                <div class="input-group input-group-merge">
                  <input v-model="password" name="password" type="password" class="form-control"
                    :placeholder="$t('newPasswordPlaceholder')" :class="{
                      'is-invalid': submitted && v$.password.$error,
                    }" @blur="v$.password.$touch" />
                </div>
                <div v-for="error of v$.password.$errors" :key="error.$uid">
                  <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                </div>
              </div>

              <div class="form-group">
                <label for="confirmPassword">{{ $t('confirmPasswordLabel') }}
                  <span class="text-danger">{{ $t('requiredAsterisk2') }}</span>
                </label>
                <div class="input-group input-group-merge">
                  <input v-model="confirmPassword" name="confirmPassword" type="password" class="form-control"
                    :placeholder="$t('confirmPasswordPlaceholder')" :class="{
                      'is-invalid': submitted && v$.confirmPassword.$error,
                    }" @blur="v$.confirmPassword.$touch" />
                </div>
                <div v-for="error of v$.confirmPassword.$errors" :key="error.$uid">
                  <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                </div>
              </div>
              <div class="d-grid">
                <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">{{ getButtonLabel()
                  }}</button>
              </div>
            </form>
          </div>
          <div class="google-bg text-center">
            <p class="mb-0">
              {{ $t('newUserPrompt') }} <router-link to="register">{{ $t('createAccountLink') }}</router-link> or
              <router-link to="login">{{ $t('loginLink') }}</router-link>
            </p>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { Form, Field } from "vee-validate";
import { router } from "@/router";
import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  sameAs,
  maxLength,
  numeric,
} from '@vuelidate/validators'
export default {
  data() {
    return {
      emailError: "",
      fullName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      verified: false,
      //confirmPassword: "",

      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
      isLoading: false,
      error: '',


      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      submit: false,
      submitted: false,
      formsubmit: false,
      typesubmit: false,

      showSuccessDialog: false,

      message: "",
      studentId: ""
    };
  },
  validations() {
    return {
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, minLength: minLength(6), sameAs: sameAs(this.password) },
    }
  },
  components: {
    Form,
    Field,
  },
  mounted() {
    this.phoneNumber = localStorage.getItem('verificationNumber');
    this.studentId = localStorage.getItem('studentId');
    this.forgotPassword = JSON.parse(localStorage.getItem('forgotPassword'));
    this.resetPassword = localStorage.getItem('resetPassword');
    if (!this.resetPassword) {
      console.log('Redirecting page...');
      this.$router.push("/forgot-password");
    }
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    async handleSubmit(e) {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        alert('Error')
        return
      }
      else
        this.checkUser()
    },
    getButtonLabel() {
      if (!this.isLoading)
        return "Reset";
      else
        return "Resetting ...";
    },
    saveUserInfo() {
      localStorage.setItem("phoneNumber", this.phoneNumber);
      //localStorage.setItem("studentId", this.studentId);
    },
    async checkUser() {
      this.isLoading = true;
      this.error = '';
      this.tryingToRegister = true;
      var responseText = "";
      //console.log(this.phoneNumber, this.password);

      try {
        await axios.put('/api/users/' + this.studentId + '/reset-password', {
          password: this.password,
        }).then((response) => {
          //this.error=response.data.error;

          this.isLoading = false;
          console.log(response.data.message);
          this.message = response.data.message;
          this.studentId = response.data.studentId;
          this.isRegisterError = response.data.error;
          this.tryingToRegister = false;
          this.showSuccessDialog = !this.isRegisterError;
          localStorage.setItem("resetPassword", false);
          //console.log('>>>>>> verified: '+this.verified+' | '+this.studentId)

          if (this.showSuccessDialog) {
            //localStorage.setItem("verificationNumber", this.phoneNumber);
            //localStorage.setItem("studentId", this.studentId);
            localStorage.setItem("forgotPassword", false);

            setTimeout(() => this.$router.push("/login"), 2000);
          }
          //this.$router.push("/login");
        })

        // Clear form and show success message
        /* this.fullName = '';
        this.phoneNumber = '';
        this.password = '';
        this.confirmPassword = ''; */
      } catch (error) {
        console.log("There is an error: " + error);
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
      }
    },
  },
};
</script>

import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import am from './locales/am.json'

const messages = {
    en,
    am
}

const i18n = createI18n({
    locale: 'am', // set locale
    fallbackLocale: 'am', // set fallback locale
    messages, // set locale messages
})

export default i18n

<template>
    <!-- Footer Top -->
    <div class="footer-top">
        <partners />
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-about">
                        <div class="footer-logo">
                            <img src="@/assets/img/logo.svg" alt="logo">
                        </div>
                        <div class="footer-about-content">
                            <p>{{ $t('footerAboutText') }}</p>
                        </div>
                    </div>
                    <!-- /Footer Widget -->

                </div>

                <div class="col-lg-4 col-md-6">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-menu">
                        <h2 class="footer-title">{{ $t('footerTitleUsers') }}</h2>
                        <ul>
                            <li><router-link to="/login">{{ $t('footerLoginLink') }}</router-link></li>
                            <li><router-link to="/register">{{ $t('footerRegisterLink') }}</router-link></li>
                        </ul>
                    </div>
                    <!-- /Footer Widget -->

                </div>

                <div class="col-lg-4 col-md-6">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-contact">
                        <div class="footer-contact-info">
                            <div class="footer-address">
                                <img src="@/assets/img/icon/cloud.svg" alt="" class="img-fluid">
                                <p>{{ $t('footerPoweredByText') }}</p>
                            </div>
                            <div class="footer-address">
                                <img src="@/assets/img/icon/icon-20.svg" alt="" class="img-fluid">
                                <p>{{ $t('footerAddressText') }}</p>
                            </div>
                            <p>
                                <img src="@/assets/img/icon/icon-19.svg" alt="" class="img-fluid">
                                {{ $t('footerEmailText') }}
                            </p>
                            <p class="mb-0">
                                <img src="@/assets/img/icon/icon-21.svg" alt="" class="img-fluid">
                                {{ $t('footerPhoneText') }}
                            </p>
                        </div>
                    </div>
                    <!-- /Footer Widget -->

                </div>

            </div>
        </div>
    </div>
    <!-- /Footer Top -->
</template>

<script>
import Partners from './partners.vue';

export default {
    data() {
        return {
            storedPhoneNumber: '',
            storedStudentId: '',
            isLoggedIn: false,
        }
    },
    mounted() {
        this.storedPhoneNumber = localStorage.getItem("phoneNumber");
        this.storedStudentId = localStorage.getItem("studentId");
        if (this.storedPhoneNumber)
            if (this.storedPhoneNumber.length > 0)
                this.isLoggedIn = true;
    }
}
</script>
<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <coursegridbreadcrumb></coursegridbreadcrumb>
    <!-- Course -->
    <section class="course-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="showing-list">
              <div class="row">
                <div>
                  <div class="show-filter add-course-info">
                    <form action="#">
                      <div class="row gx-2 align-items-center">
                        <div class="col-md-12 col-item">
                          <div class="search-group">
                            <i class="feather-search"></i>
                            <input type="text" v-model="searchText" class="form-control" :placeholder=getPlaceHolder()
                              :disabled="loading" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-9">
            <!--div class="showing-list">
              <div class="row">
                <div class="col-lg-6">
                  <div class="show-filter add-course-info">
                    <form action="#">
                      <div class="row gx-2 align-items-center">
                        <div class="col-md-12 col-item">
                          <div class="search-group">
                            <i class="feather-search"></i>
                            <input type="text" v-model="searchText" class="form-control" placeholder="Search our courses"
                              :disabled="loading" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div-->

            <div v-if="loading" class="row">
              <div v-for="item in numbers" :key="item" class="col-lg-4 col-md-6 d-flex mt-5 mb-5">
                <facebook-loader :speed="2" :animate="true"></facebook-loader>
                <!--code-loader :speed="2" :animate="true" ></code-loader-->
              </div>
            </div>

            <div v-if="!loading && filteredItems.length == 0" class="row">
              <h3>{{ $t('noCourseFoundMessage') }}</h3>
            </div>

            <div v-else class="row">
              <div v-for="item in filteredItems" :key="item.id" class="col-lg-4 col-md-6 d-flex">
                <div class="course-box course-design d-flex">
                  <div class="product">
                    <div class="product-img">
                      <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">
                        <img v-if="item.thumbnail.length > 0" class="img-fluid" alt="" :src="item.thumbnail" />
                        <img v-else class="img-fluid" alt="" src="@/assets/img/course/courses_sample.jpg" />
                      </router-link>
                      <div class="tagContainer">
                        <span v-for="tag in item.tags">
                          <span>
                            {{ tag }}
                          </span><a style="margin-inline-start: 0.3rem;"></a>
                        </span>
                      </div>
                    </div>
                    <div class="product-content">
                      <!--div class="course-group d-flex">
                        
                        <div
                          class="course-share d-flex align-items-center justify-content-center"
                        >
                          <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                        </div>
                      </div-->
                      <h3 class="title" style="text-align: left; color: #000000;
                      display: -webkit-box;
                      Webkit-line-clamp: 4.5;
                      webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.5em; /* Define height of line */
                      height: 3em; 
                      ">
                        <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">{{ item.title
                          }}</router-link>
                      </h3>
                      <div class="course-info d-flex align-items-center">
                        <!--div class="rating-img d-flex align-items-center">
                          <img src="@/assets/img/icon/icon-01.svg" alt="" />
                          <p>12+ Lectures</p>
                        </div-->
                        <div class="rating-img d-flex align-items-center">
                          <img src="@/assets/img/icon/icon-02.svg" alt="" />
                          <p>{{ formatDuration(item.courseDuration) }}</p>
                        </div>
                        <div class="course-view d-flex align-items-center">
                          <img src="@/assets/img/icon/people.svg" alt="" />
                          <p>{{ item.students.length }} {{ $t('studentsEnrolled') }}</p>
                        </div>
                      </div>
                      <!--div class="rating">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          ><span>4.0</span> (15)</span
                        >
                      </div-->
                      <div>
                        <h5 style="text-align: left; font-size: 14px; color: #BBBBBB;
                      display: -webkit-box;
                      Webkit-line-clamp: 15;
                      webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.5em; /* Define height of line */
                      height: 4.5em; 
                      ">
                          {{ item.description }}
                        </h5>
                      </div>

                      <div class="all-btn all-courses d-flex align-items-center" style="padding-top: 10px;">
                        <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }"
                          class="btn btn-primary">{{ $t('learnMoreButton') }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--pagination v-if="filteredItems.length > 0"></pagination-->
          </div>
          <gridlistsidebar></gridlistsidebar>
        </div>
      </div>
    </section>
    <!-- /Course -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import dashboard from "@/assets/json/dashboard.json";
import "vue3-carousel/dist/carousel.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { CodeLoader, FacebookLoader } from 'vue-content-loader';
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    CodeLoader, FacebookLoader
  },
  data() {
    return {
      Category: ["Category", "Angular", "Node Js", "React", "Python"],
      dashboard: dashboard,
      courses: [],
      numbers: [1, 2, 3],
      loading: true,
      searchText: '',
    };
  },

  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    getPlaceHolder() {
      return this.$t('searchPlaceholder');
    },
    submitForm() {
      this.$router.push("/course-list");
    },
    async getCourses() {
      try {
        const response = await axios.get('/api/courses');
        this.courses = response.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    },
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}hr ${remainingMinutes}min`;
    },
  },
  mounted() {
    this.getCourses();
    this.$nextTick(() => {
      AOS.init();
    });

  },
  computed: {
    filteredItems() {
      // Filter items based on the search text
      const searchLower = this.searchText.toLowerCase();
      return this.courses.filter(item => item.title.toLowerCase().includes(searchLower));
    }
  }
};
</script>

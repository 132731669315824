<template>
	<!-- Main Wrapper -->
	<div class="main-wrapper">

		<layouts2></layouts2>

		<faqbreadcrumb></faqbreadcrumb>

		<!-- Help Details -->
		<div class="help-sec">

			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="help-title">
							<h1>{{ $t('faqTitle') }}</h1>
							<p>{{ $t('faqSubtitle') }}</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-6">

						<!-- Faq -->
						<div class="faq-card">
							<h6 class="faq-title">
								<a class="collapsed" data-bs-toggle="collapse" href="#faqone" aria-expanded="false">{{
									$t('faqOneQuestion') }}</a>
							</h6>
							<div id="faqone" class="collapse">
								<div class="faq-detail">
									<p>{{ $t('faqOneAnswer') }}</p>
								</div>
							</div>
						</div>


						<div class="faq-card">
							<h6 class="faq-title">
								<a class="collapsed" data-bs-toggle="collapse" href="#faqtwo" aria-expanded="false">{{
									$t('faqTwoQuestion') }}</a>
							</h6>
							<div id="faqtwo" class="collapse">
								<div class="faq-detail">
									<p>{{ $t('faqTwoAnswer') }}</p>
								</div>
							</div>
						</div>


						<div class="faq-card">
							<h6 class="faq-title">
								<a class="collapsed" data-bs-toggle="collapse" href="#faqthree" aria-expanded="false">{{
									$t('faqThreeQuestion') }}</a>
							</h6>
							<div id="faqthree" class="collapse">
								<div class="faq-detail">
									<p>{{ $t('faqThreeAnswer') }}</p>
								</div>
							</div>
						</div>
						<!-- /Faq -->

					</div>

					<div class="col-lg-6">

						<!-- Faq -->
						<div class="faq-card">
							<h6 class="faq-title">
								<a class="collapsed" data-bs-toggle="collapse" href="#faqfour" aria-expanded="false">{{
									$t('faqFourQuestion') }}</a>
							</h6>
							<div id="faqfour" class="collapse">
								<div class="faq-detail">
									<p>{{ $t('faqFourAnswer') }}</p>
								</div>
							</div>
						</div>


						<div class="faq-card">
							<h6 class="faq-title">
								<a class="collapsed" data-bs-toggle="collapse" href="#faqfive" aria-expanded="false">{{
									$t('faqFiveQuestion') }}</a>
							</h6>
							<div id="faqfive" class="collapse">
								<div class="faq-detail">
									<p>{{ $t('faqFiveAnswer') }}</p>
								</div>
							</div>
						</div>


						<div class="faq-card">
							<h6 class="faq-title">
								<a class="collapsed" data-bs-toggle="collapse" href="#faqsix" aria-expanded="false">{{
									$t('faqSixQuestion') }}</a>
							</h6>
							<div id="faqsix" class="collapse">
								<div class="faq-detail">
									<p>{{ $t('faqSixAnswer') }}</p>
								</div>
							</div>
						</div>
						<!-- /Faq -->

					</div>
				</div>
			</div>
		</div>
		<!-- /Help Details -->

		<layouts1></layouts1>

	</div>
	<!-- /Main Wrapper -->
</template>

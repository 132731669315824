<template>
  <div class="home-three">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layoutsindex></layoutsindex>
      <homethreebanner></homethreebanner>
      <favouritethree></favouritethree>
      <acheivegoals></acheivegoals>
      <instructorthree></instructorthree>
      <footerthree></footerthree>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  name: "index-three",
};
</script>

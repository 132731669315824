<template>
  <div class="col-lg-3 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="filter-clear">
        <!--div class="clear-filter d-flex align-items-center">
          <h4><i class="feather-filter"></i>Filters</h4>
          <div class="clear-text">
            <p>CLEAR</p>
          </div>
        </div-->

        <!-- Search Filter -->
        <!--div class="card search-filter">
          <div class="card-body">
            <div class="filter-widget mb-0">
              <div class="categories-head d-flex align-items-center">
                <h4>Course categories</h4>
                <i class="fas fa-angle-down"></i>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Backend (3)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> CSS (2)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Frontend (2)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" checked />
                  <span class="checkmark"></span> General (2)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" checked />
                  <span class="checkmark"></span> IT & Software (2)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Photography (2)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Programming Language (3)
                </label>
              </div>
              <div>
                <label class="custom_check mb-0">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Technology (2)
                </label>
              </div>
            </div>
          </div>
        </div-->
        <!-- /Search Filter -->

        <!-- Search Filter -->
        <!--div class="card search-filter">
          <div class="card-body">
            <div class="filter-widget mb-0">
              <div class="categories-head d-flex align-items-center">
                <h4>Instructors</h4>
                <i class="fas fa-angle-down"></i>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Keny White (10)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> Hinata Hyuga (5)
                </label>
              </div>
              <div>
                <label class="custom_check">
                  <input type="checkbox" name="select_specialist" />
                  <span class="checkmark"></span> John Doe (3)
                </label>
              </div>
              <div>
                <label class="custom_check mb-0">
                  <input type="checkbox" name="select_specialist" checked />
                  <span class="checkmark"></span> Nicole Brown
                </label>
              </div>
            </div>
          </div>
        </div-->
        <!-- /Search Filter -->

        <!-- Search Filter -->
        <!--div class="card search-filter">
          <div class="card-body">
            <div class="filter-widget mb-0">
              <div class="categories-head d-flex align-items-center">
                <h4>Price</h4>
                <i class="fas fa-angle-down"></i>
              </div>
              <div>
                <label class="custom_check custom_one">
                  <input type="radio" name="select_specialist" />
                  <span class="checkmark"></span> All (18)
                </label>
              </div>
              <div>
                <label class="custom_check custom_one">
                  <input type="radio" name="select_specialist" />
                  <span class="checkmark"></span> Free (3)
                </label>
              </div>
              <div>
                <label class="custom_check custom_one mb-0">
                  <input type="radio" name="select_specialist" checked />
                  <span class="checkmark"></span> Paid (15)
                </label>
              </div>
            </div>
          </div>
        </div-->
        <!-- /Search Filter -->

        <!-- Latest Posts -->
        <div class="card post-widget">
          <div class="card-body">
            <div class="latest-head">
              <h4 class="card-title">{{ $t('latestCoursesLabel') }}</h4>
            </div>
            <ul v-if="loading" class="latest-posts">
              <li v-for="item in numbers" :key="item">
                <facebook-loader :speed="2" :animate="true"></facebook-loader>
              </li>
            </ul>
            <ul v-else class="latest-posts">
              <li v-for="item in courses" :key="item.id">
                <div class="post-thumb">
                  <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">
                    <img v-if="item.thumbnail.length > 0" class="img-fluid" alt="" :src="item.thumbnail" />
                    <img v-else class="img-fluid" alt="" src="@/assets/img/course/courses_sample.jpg" />
                  </router-link>
                </div>
                <div class="post-info free-color">
                  <h4>
                    <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">{{ item.title
                      }}</router-link>
                  </h4>
                </div>
              </li>

            </ul>
          </div>
        </div>
        <!-- /Latest Posts -->
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import dashboard from "@/assets/json/dashboard.json";
import "vue3-carousel/dist/carousel.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { CodeLoader, FacebookLoader } from 'vue-content-loader';
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    CodeLoader, FacebookLoader
  },
  data() {
    return {
      Category: ["Category", "Angular", "Node Js", "React", "Python"],
      dashboard: dashboard,
      courses: [],
      numbers: [1, 2, 3],
      loading: true
    };
  },

  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/course-list");
    },
    async getCourses() {
      try {
        const response = await axios.get('/api/courses/limit?limit=5');
        this.courses = response.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    },
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}hr ${remainingMinutes}min`;
    },
  },
  mounted() {
    this.getCourses();
    this.$nextTick(() => {
      AOS.init();
    });

  },
};
</script>

<template>
  <!-- Home Banner -->
  <section class="home-slide d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div class="home-slide-face aos " style="margin-top: 120px;" data-aos="fade-up">
            <div class="home-slide-text">
              <!--h5>The Leader in Online Learning</h5-->
              <small><span
                  style="color: #666666;letter-spacing:6px; font-weight: 100; font-size:12px; text-transform: uppercase;">{{
                    $t('empowerMind') }}</span></small>
              <h1><span style="color: #2574F3;">{{ $t('magerTitle') }}</span><br />
                {{ $t('mainTitle') }}</h1>
            </div>
            <!--div class="banner-content">
              <form class="form" @submit.prevent="submitForm">
                <div class="form-inner">
                  <div class="input-group">
                    <i class="fa-solid fa-magnifying-glass search-icon"></i>
                    <input type="email" class="form-control" placeholder="Search courses" />
                    <span class="drop-detail">
                      <vue-select
                        :options="Category"
                        placeholder="Category"
                        class="vueselect"
                      />
                    </span>
                    <button class="btn btn-primary sub-btn" type="submit">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div-->
            <div>
              &nbsp;
            </div>
            <div class="trust-user">
              <p style="font-weight: 100;">{{ $t('trustedExpertise') }}</p>
              <div class="trust-rating d-flex align-items-center">
                <div class="rate-head">
                  <h2>
                    <span><vue3-autocounter class="counter" ref="counter" :startAmount="0" :delay="3" :endAmount="10"
                        :duration="2" :autoinit="true" /></span>{{ $t('coursesComingSoon') }}
                  </h2>
                </div>
                <!--div class="rating d-flex align-items-center">
                  <h2 class="d-inline-block average-rating">4.4</h2>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                </div-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 d-flex align-items-center">
          <div class="girl-slide-img aos" data-aos="fade-up">
            <img src="@/assets/img/object.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->
  <section class="section student-course">
    <div class="container">
      <div class="course-widget">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="course-full-width">
              <div class="blur-border course-radius align-items-center aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/courses.png" alt="" height="80" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span style="font-weight: bold;"><vue3-autocounter class="counter" ref="counter" :startAmount="1"
                          :delay="3" :endAmount="4" :duration="2" :autoinit="true" /></span>+
                    </h4>
                    <p>{{ $t('courses') }}</p>
                    <!--span style="color: #BBBBBB; font-weight: 100; font-size:12px;">Discover excellence in every lesson with our quality courses.</span-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="course-full-width">
              <div class="blur-border course-radius aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/users.png" alt="" height="80" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span style="font-weight: bold;"><vue3-autocounter class="counter" ref="counter" :startAmount="1"
                          :delay="3" :endAmount="10" :duration="2" :autoinit="true" /></span>0+
                    </h4>
                    <p>{{ $t('users') }}</p>
                    <!--span style="color: #BBBBBB; font-weight: 100; font-size:12px;">Gain unparalleled insights from industry experts guiding you every step of the way.</span-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="course-full-width">
              <div class="blur-border course-radius aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/certifications.png" alt="" height="80" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span style="font-weight: bold;"><vue3-autocounter class="counter" ref="counter" :startAmount="1"
                          :delay="3" :endAmount="5" :duration="2" :autoinit="true" /></span>+
                    </h4>
                    <p>{{ $t('certifications') }}</p>
                    <!--span style="color: #BBBBBB; font-weight: 100; font-size:12px;">Seamlessly access content across multiple platforms for a flexible and convenient learning experience.</span-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="course-full-width">
              <div class="blur-border course-radius aos" data-aos="fade-up">
                <div class="online-course d-flex align-items-center">
                  <div class="course-img">
                    <img src="@/assets/img/experts.png" alt="" height="80" />
                  </div>
                  <div class="course-inner-content">
                    <h4>
                      <span><vue3-autocounter class="counter" ref="counter" :startAmount="1" :delay="3" :endAmount="5"
                          :duration="2" :autoinit="true" /></span>+
                    </h4>
                    <p>{{ $t('experts') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Home Banner -->

  <!-- Top Categories -->
  <section class="section how-it-works">
    <div class="container">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head" style="line-height: 0%;">
          <span
            style="color: #2574F3;letter-spacing:6px; font-weight: 100; font-size:12px; text-transform: uppercase;">{{
              $t('topCoursesTailored') }}</span>
          <h2>{{ $t('recentlyAddedCourses') }}</h2>
        </div>
        <div class="all-btn all-category d-flex align-items-center">
          <router-link to="course-grid" class="btn btn-primary">{{ $t('allCoursesButton') }}</router-link>
        </div>
      </div>
      <div class="section-text aos" data-aos="fade-up">
        <p>
          {{ $t('transformativeLearningJourney') }}
        </p>
      </div>
      <div class="row mt-5" data-aos="fade-up">
        <div v-if="loading" v-for="item in numbers" :key="item" class="col-lg-3 col-md-6 d-flex mt-5 mb-5">
          <facebook-loader :speed="2" :animate="true"></facebook-loader>
          <!--code-loader :speed="2" :animate="true" ></code-loader-->
        </div>

        <div v-else v-for="item in courses" :key="item.id" class="col-lg-3 col-md-6 d-flex">
          <div class="course-box course-design d-flex">
            <div class="product">
              <div class="product-img">
                <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">
                  <img v-if="item.thumbnail.length > 0" class="img-fluid" alt="" :src="item.thumbnail" width="500"
                    height="325" />
                  <img v-else class="img-fluid" alt="" src="@/assets/img/course/courses_sample.jpg" />
                </router-link>
                <!--router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">
                  <vue-load-image>
                    <template v-slot:image>
                      <img class="img-fluid" :src="item.thumbnail" />
                    </template>
<template v-slot:preloader>
                      <img src="@/assets/img/loading.gif" />
                    </template>
<template v-slot:error>Unable to load image</template>
</vue-load-image>
</router-link-->

                <div class="tagContainer">
                  <span v-for="tag in item.tags">
                    <span>
                      {{ tag }}
                    </span><a style="margin-inline-start: 0.3rem;"></a>
                  </span>
                </div>
              </div>
              <div class="product-content">
                <!--div class="course-group d-flex">
                        
                        <div
                          class="course-share d-flex align-items-center justify-content-center"
                        >
                          <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                        </div>
                      </div-->
                <h3 class="title" style="text-align: left; color: #000000;
                      display: -webkit-box;
                      Webkit-line-clamp: 4.5;
                      webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.5em; /* Define height of line */
                      height: 3em; 
                      ">
                  <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }">{{ item.title
                    }}</router-link>
                </h3>
                <div class="course-info d-flex align-items-center">
                  <!--div class="rating-img d-flex align-items-center">
                          <img src="@/assets/img/icon/icon-01.svg" alt="" />
                          <p>12+ Lectures</p>
                        </div-->
                  <div class="rating-img d-flex align-items-center">
                    <img src="@/assets/img/icon/icon-02.svg" alt="" />
                    <p>{{ formatDuration(item.courseDuration) }}</p>
                  </div>
                  <div class="course-view d-flex align-items-center">
                    <img src="@/assets/img/icon/people.svg" alt="" />
                    <p>{{ item.students.length }} {{ $t('studentsEnrolled') }}</p>
                  </div>
                </div>
                <!--div class="rating">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star me-1"></i>
                        <span class="d-inline-block average-rating"
                          ><span>4.0</span> (15)</span
                        >
                      </div-->
                <div>
                  <h5 style="text-align: left; font-size: 14px; color: #BBBBBB;
                      display: -webkit-box;
                      Webkit-line-clamp: 15;
                      webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.5em; /* Define height of line */
                      height: 4.5em; 
                      ">
                    {{ item.description }}
                  </h5>
                </div>

                <div class="all-btn all-courses d-flex align-items-center" style="padding-top: 10px;">
                  <router-link :to="{ name: 'course-overview', params: { courseId: item.id } }"
                    class="btn btn-primary">{{
                      $t('learnMoreButton') }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="item in courses" :key="item.id" class="owl-carousel mentoring-course owl-theme">
          <div>
                <div class="course-box course-design d-flex">
                  <div class="product">
                    <div class="product-img">
                      <router-link :to="{name:'course-overview', params:{courseId:item.id}}">
                        <img v-if="item.thumbnail.length>0"
                          class="img-fluid"
                          alt=""
                          :src="item.thumbnail"
                        />
                        <img v-else
                          class="img-fluid"
                          alt=""
                          src="@/assets/img/course/courses_sample.jpg"
                        />
                      </router-link>
                    </div>
                    <div class="product-content">
                      <h3 class="title" style="text-align: left; color: #000000;
                      display: -webkit-box;
                      Webkit-line-clamp: 4.5;
                      webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.5em; /* Define height of line */
                      height: 3em; 
                      ">
                        <router-link  :to="{name:'course-overview', params:{courseId:item.id}}"
                          >{{ item.title }}</router-link
                        >
                      </h3>
                      <div class="course-info d-flex align-items-center">
                        <div class="rating-img d-flex align-items-center">
                          <img src="@/assets/img/icon/icon-02.svg" alt="" />
                          <p>{{ formatDuration(item.courseDuration) }}</p>
                        </div>
                        <div class="course-view d-flex align-items-center">
                          <img src="@/assets/img/icon/people.svg" alt="" />
                          <p>{{ item.students.length }} {{ $t('studentsEnrolled') }}</p>
                        </div>
                      </div>
                      <div>
                      <h5 style="text-align: left; font-size: 14px; color: #BBBBBB;
                      display: -webkit-box;
                      Webkit-line-clamp: 15;
                      webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1.5em; /* Define height of line */
                      height: 4.5em; 
                      ">
                        {{ item.description }}
                      </h5>
                    </div>
                      
                      <div class="all-btn all-courses d-flex align-items-center" style="padding-top: 10px;">
                        <router-link :to="{name:'course-overview', params:{courseId:item.id}}" class="btn btn-primary"
                          >{{ $t('learnMoreButton') }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel-->
    </div>
  </section>
  <!-- /Top Categories -->
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import dashboard from "@/assets/json/dashboard.json";
import "vue3-carousel/dist/carousel.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { CodeLoader, FacebookLoader } from 'vue-content-loader';
import VueLoadImage from "vue-load-image";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    CodeLoader, FacebookLoader,
    "vue-load-image": VueLoadImage
  },
  data() {
    return {
      Category: ["Category", "Angular", "Node Js", "React", "Python"],
      dashboard: dashboard,
      courses: [],
      numbers: [1, 2, 3, 4],
      loading: true
    };
  },

  setup() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/course-list");
    },
    async getCourses() {
      try {
        const response = await axios.get('/api/courses/limit?limit=4');
        this.courses = response.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    },
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}hr ${remainingMinutes}min`;
    },
  },
  mounted() {
    this.getCourses();
    this.$nextTick(() => {
      AOS.init();
    });

  },
};
</script>

<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <logo-with-language />
            <h1>{{ $t('signUpTitle') }}</h1>
            <b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.message }}</b-alert>

            <b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.message }}</b-alert>
            <form @submit.prevent="handleSubmit" class="register">

              <div class="form-group">
                <label for="fullName">{{ $t('fullNameLabel') }}
                  <span class="text-danger">{{ $t('requiredAsterisk1') }}</span>
                </label>
                <div class="input-group input-group-merge">
                  <input v-model="fullName" name="fullName" type="text" class="form-control"
                    :placeholder="$t('fullNamePlaceholder')" :class="{
                      'is-invalid': submitted && v$.fullName.$error,
                    }" @blur="v$.fullName.$touch" />
                </div>
                <div v-for="error of v$.fullName.$errors" :key="error.$uid">
                  <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label for="phoneNumber">{{ $t('phoneNumberLabel') }}
                    <span class="text-danger">{{ $t('requiredAsterisk2') }}</span>
                  </label>
                  <div class="input-group input-group-merge">
                    <input v-model="phoneNumber" name="phoneNumber" type="phone" class="form-control"
                      :placeholder="$t('phoneNumberPlaceholder')" :class="{
                        'is-invalid': submitted && v$.phoneNumber.$error,
                      }" @blur="v$.phoneNumber.$touch" />
                  </div>
                  <div v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
                    <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                  </div>
                </div>

                <div class="form-group col-md-6">
                  <label for="password">{{ $t('passwordLabel') }}
                    <span class="text-danger">{{ $t('requiredAsterisk2') }}</span>
                  </label>
                  <div class="input-group input-group-merge">
                    <input v-model="password" name="password" type="password" class="form-control"
                      :placeholder="$t('passwordPlaceholder')" :class="{
                        'is-invalid': submitted && v$.password.$error,
                      }" @blur="v$.password.$touch" />
                  </div>
                  <div v-for="error of v$.password.$errors" :key="error.$uid">
                    <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label for="sex">{{ $t('sexLabel') }}
                    <span class="text-danger">*</span>
                  </label>
                  <select v-model="sex" name="sex" class="form-control">
                    <option value="ሴት / Female">ሴት / Female</option>
                    <option value="ወንድ / Male">ወንድ / Male</option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="ageRange">{{ $t('ageRangeLabel') }}
                    <span class="text-danger">*</span>
                  </label>
                  <select v-model="ageRange" name="ageRange" class="form-control">
                    <option value="-18">-18</option>
                    <option value="18-25">18-25</option>
                    <option value="26-35">26-35</option>
                    <option value="36-45">36-45</option>
                    <option value="46-55">46-55</option>
                    <option value="56-65">56-65</option>
                    <option value="66+">66+</option>
                  </select>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">{{ getButtonLabel()
                  }}</button>
              </div>
            </form>
          </div>
          <div class="google-bg text-center">
            <p class="mb-0">
              {{ $t('alreadyHaveAccountPrompt') }} <router-link to="login">{{ $t('signInLink') }}</router-link>
            </p>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { Form, Field } from "vee-validate";
import { router } from "@/router";
import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  sameAs,
  maxLength,
  numeric,
} from '@vuelidate/validators'

export default {
  data() {
    return {
      fullName: "",
      phoneNumber: "",
      password: "",
      sex: "ሴት / Female",
      ageRange: "26-35",
      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
      isLoading: false,
      error: '',

      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      submit: false,
      submitted: false,
      formsubmit: false,
      typesubmit: false,

      showSuccessDialog: false,

      message: ""
    };
  },
  validations() {
    return {
      fullName: { required, minLength: minLength(3) },
      phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      password: { required, minLength: minLength(6) },
      sex: { required },
      ageRange: { required },
    }
  },
  components: {
    Form,
    Field,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  mounted() {
    localStorage.setItem("forgotPassword", false);
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    async handleSubmit(e) {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        //alert('Error')
        return
      }
      else
        this.register()
    },

    getButtonLabel() {
      if (!this.isLoading)
        return this.$t('createAccountButtonLabel');
      else
        return this.$t('creatingAccountButtonLabel');
    },
    /* ß */

    async register() {
      this.isLoading = true;
      this.error = '';
      this.tryingToRegister = true;
      this.isRegisterError = false;
      this.showSuccessDialog = false;
      var responseText = "";
      //console.log(this.fullName, this.phoneNumber, this.password);

      try {
        await axios.post('/api/users/register', {
          fullName: this.fullName,
          phoneNumber: this.phoneNumber,
          password: this.password,
          sex: this.sex,
          ageRange: this.ageRange,
        }).then((response) => {
          //this.error=response.data.error;

          this.isLoading = false;
          //console.log(response.data.message);
          this.message = response.data.message;
          this.isRegisterError = response.data.error;
          this.tryingToRegister = false;
          this.showSuccessDialog = !this.isRegisterError;
          if (this.showSuccessDialog)
            setTimeout(() => this.$router.push("/login"), 2000);
          //this.$router.push("/login");
        })

        // Clear form and show success message
        /* this.fullName = '';
        this.phoneNumber = '';
        this.password = '';
        this.confirmPassword = ''; */
      } catch (error) {
        console.log("There is an error: " + error);
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
      }
    },
  },
};
</script>

<template>
  <!-- Header -->
  <header v-bind:class="$route.meta.headerClass">
    <div class="header-fixed">
      <nav class="navbar navbar-expand-lg scroll-sticky" v-bind:class="$route.meta.NavbarClass"
        :class="{ 'add-header-bg': isSticky }">
        <div class="container">
          <div class="navbar-header">
            <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <router-link to="/" class="navbar-brand logo" v-if="isHomethreeRoute">
              <img :src="require('@/assets/img/logo.svg')" class="img-fluid" alt="Logo" />
            </router-link>
            <router-link to="/" class="navbar-brand logo" v-else-if="isHomefourRoute">
              <img :src="require('@/assets/img/logo.svg')" class="img-fluid" alt="Logo" />
            </router-link>
            <router-link to="/" class="navbar-brand logo" v-else>
              <img :src="require('@/assets/img/logo.svg')" class="img-fluid" alt="Logo" />
            </router-link>
          </div>
          <div class="main-menu-wrapper">
            <div class="menu-header">
              <router-link to="/" class="menu-logo">
                <img :src="require('@/assets/img/logo.svg')" class="img-fluid" alt="Logo" />
              </router-link>
              <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeSidebar">
                <i class="fas fa-times"></i>
              </a>
            </div>
            <mainnav></mainnav>
          </div>


          <!--template v-if="isLoggedIn">
            <ul class="main-nav">
              <li>
            <router-link to="/" @click="handleLogout"><i class="feather-log-out me-1"></i> Logout</router-link>

              </li>
            </ul>
          </template-->
          <ul class="nav header-navbar-rht" v-if="isLoggedIn">
            <li class="nav-item user-nav">
              <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
                <span class="user-img">
                  <img v-if="profilePicture.length > 0" :src="profilePicture" class="avatar-img rounded-circle" />
                  <img v-else :src="profilePicturePlaceholder" class="avatar-img rounded-circle" />
                  <span class="status online"></span>
                </span>
              </a>
              <div class="users dropdown-menu dropdown-menu-right" data-popper-placement="bottom-end">
                <div class="user-header" style="vertical-align: middle;">
                  <div class="avatar avatar-sm">
                    <img v-if="profilePicture.length > 0" :src="profilePicture" class="avatar-img rounded-circle" />
                    <img v-else :src="profilePicturePlaceholder" class="avatar-img rounded-circle" />
                  </div>
                  <div class="user-text" style="padding: 10px 0; align-items: center;">
                    <h6>{{ truncatedText(fullName) }}</h6>
                    <p class="text-muted mb-0" style="font-size: 8px; letter-spacing: 1px;">{{ $t('userEnrolledText') }}
                      {{
                        coursesEnrolled }} {{ $t('enrolledInCourses') }}</p>
                  </div>
                </div>
                <router-link class="dropdown-item" to="/setting-edit-profile"><i class="feather-user me-1"></i>
                  {{ $t('userProfileText') }}</router-link>

                <router-link class="dropdown-item" to="/login" @click="handleLogout"><i
                    class="feather-log-out me-1"></i>
                  {{ $t('logoutText') }}</router-link>

              </div>
            </li>

          </ul>
          <signpages v-else></signpages>


        </div>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
import authService from '@/authService';
export default {
  data() {
    return {
      isSticky: false,
      isSidebarOpen: true,
      storedPhoneNumber: '',
      isLoggedIn: false,

      userData: [],
      fullName: '',
      profilePicture: '',
      profilePicturePlaceholder: 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e',
      coursesEnrolled: 0,

logo: require('@/assets/img/logo.svg')
    };
  },
  computed: {
    isHomethreeRoute() {
      return this.$route.path === "/index-three";
    },
    isHomefourRoute() {
      return this.$route.path === "/index-four";
    },
    // isHomeothers() {
    //   return this.$route.path === "/" || this.$route.path === "/index-two";
    // },
  },
  mounted() {
    //console.log('>>>> Mounted: this.storedPhoneNumber: '+this.storedPhoneNumber);
    this.storedPhoneNumber = localStorage.getItem("phoneNumber");
    if (this.storedPhoneNumber)
      if (this.storedPhoneNumber.length > 0)
        this.isLoggedIn = true;

    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData) {
      console.log('>>>> Mounted: userData: ' + this.userData.coursesEnrolled);
      this.fullName = this.userData.fullName;
      this.profilePicture = this.userData.profilePicture;
      this.coursesEnrolled = this.userData.coursesEnrolled.length
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    truncatedText(originalText) {
      // Truncate the text to maxLength characters and add ellipsis if needed
      return originalText.length > 20
        ? originalText.slice(0, 20) + '...'
        : originalText;
    },
    handleLogout() {
      localStorage.setItem("phoneNumber", '');
      localStorage.setItem("password", '');
      localStorage.setItem("studentId", '');
      //console.log('>>>>>>>>>>> Got In Here');
      //console.log('>>>>>>>>>>> '+localStorage.getItem("phoneNumber"));
      //this.$router.push('/');
      //window.location.reload();
      this.isLoggedIn = false;
      //console.log('>>>>>>>>>>> '+authService.getToken());
      authService.removeToken();
      this.$router.push('/login');
      //console.log('>>>>>>>>>>> Got In Here');
    },
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>

<template>
	<!-- Main Wrapper -->
	<div class="main-wrapper">

		<layouts2></layouts2>

		<aboutbreadcrumb></aboutbreadcrumb>

		<!-- Help Details -->
		<div class="help-sec">

			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="help-title">
							<h1>{{ $t('aboutPageTitle') }}</h1>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-6">

						<section>
							<h3>{{ $t('ourMissionTitle') }}</h3>
							<p>{{ $t('ourMissionText') }}</p>

							<h3 class="mt-5">{{ $t('whatSetsUsApartTitle') }}</h3>
							<p>{{ $t('whatSetsUsApartText1') }}</p>

							<p>{{ $t('whatSetsUsApartText2') }}</p>

						</section>


					</div>
					<div class="col-lg-6">
						<h3>{{ $t('keyFeaturesTitle') }}</h3>
						<p>
						<ul>
							<li><strong>{{ $t('diversityOfCourses') }}</strong></li>
							<li><strong>{{ $t('globalNetworkOfInstructors') }}</strong></li>
							<li><strong>{{ $t('interactiveAndEngagingContent') }}</strong></li>
							<li><strong>{{ $t('flexibleLearningPaths') }}</strong></li>
							<li><strong>{{ $t('communityCentricApproach') }}</strong></li>
						</ul>
						</p>
					</div>
					<div class="col-lg-12 mt-5">
						<h3>{{ $t('startYourLearningJourneyTitle') }}</h3>
						<p>{{ $t('startYourLearningJourneyText') }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- /Help Details -->

		<layouts1></layouts1>

	</div>
	<!-- /Main Wrapper -->
</template>

<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <privacypolicy></privacypolicy>
    <!-- Help Details -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="terms-content">
              <div class="terms-text">
                <h3>Effective date: <span>23rd of December, 2023</span></h3>
                <p>This Privacy Policy describes how Mager collects, uses, and protects the information you provide when using our platform. By using Mager, you agree to the terms outlined in this policy.</p>

  <h4>1. Information We Collect</h4>
  <p>Mager collects the following types of information:</p>
  <ol>
    <li><strong>Personal Information:</strong> When you create an account, we may collect personal information such as your name, phone number, and profile information.</li>
    <li><strong>Usage Information:</strong> We collect information about your interactions with the platform, including courses enrolled, progress, and preferences.</li>
    <li><strong>Device Information:</strong> We may collect information about the device you use to access Mager, including device type, operating system, and browser information.</li>
  </ol>

  <h4>2. How We Use Your Information</h4>
  <p>We use the collected information for the following purposes:</p>
  <ol>
    <li>To provide and improve the Mager platform.</li>
    <li>To personalize your learning experience and provide relevant content.</li>
    <li>To communicate with you about your account and platform updates.</li>
    <li>To analyze usage patterns and improve our services.</li>
  </ol>

  <h4>3. Sharing Your Information</h4>
  <p>We do not sell, trade, or rent your personal information to third parties. Your information may be shared in the following cases:</p>
  <ol>
    <li>With instructors and other users as part of the normal functioning of the platform.</li>
    <li>With third-party service providers who assist us in delivering our services.</li>
    <li>If required by law or to protect our rights and safety.</li>
  </ol>

  <h4>4. Security</h4>
  <p>Mager takes reasonable measures to protect your information. However, no online platform can guarantee absolute security. Please take steps to protect your personal information, such as choosing a strong password and keeping it confidential.</p>

  <h4>5. Your Choices</h4>
  <p>You can manage your account settings and communication preferences through the Mager platform. You may also contact us to update or delete your account information.</p>

  <h4>6. Changes to this Privacy Policy</h4>
  <p>Mager may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review the policy periodically.</p>

  <h4>7. Contact Us</h4>
  <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@mager.et">support@mager.et</a></p>

              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Help Details -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

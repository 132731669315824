<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <!--coursedetails></coursedetails-->
    <component :is="courseDetails" :courseTitle="courseTitle" />
    <!--inner-page></inner-page-->
    <component :is="innerPage" :courseId="courseId" :courseTags="courseTags" :courseTitle="courseTitle"
      :courseDescription="courseDescription" :courseDuration="courseDuration"
      :courseNumberOfLectures="courseNumberOfLectures" :courseNumberOfStudents="courseNumberOfStudents"
      :courseContentUrl="courseContentUrl" :courseOverviewUrl="courseOverviewUrl" :courseThumbnail="courseThumbnail" />
    <!--iframe :src="courseOverviewUrl" width="100%" height="100%" frameborder="0"></iframe-->
    <!--div v-if="loading">Loading...</div>
    <div v-else v-html="externalHtmlContent"></div-->


    <!-- Course Content -->
    <section class="page-content course-sec">
      <div class="container">
        <div class="row">
          <!--div class="col-lg-12 scrollable-wrapper" v-scroll-progress="setProgress">
            <div class="tall">
              <code-loader v-if="loading" :speed="2" :animate="true"></code-loader>
              <div v-else v-html="externalHtmlContent"></div>
            </div>
          </div-->
          <div class="col-lg-12">
            <code-loader v-if="loading" :speed="2" :animate="true"></code-loader>
            <div v-else v-html="externalHtmlContent"></div>

          </div>


        </div>
      </div>
    </section>

    <!-- /Pricing Plan -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import InnerPage from './inner-page.vue';
import CourseDetails from '../../../../components/breadcrumb/coursedetails.vue';
import axios from 'axios';
import { CodeLoader, FacebookLoader } from 'vue-content-loader';


export default {
  components: {
    CodeLoader, FacebookLoader
  },
  data() {
    return {
      courseId: '',
      course: null,
      courseTags: [],
      courseTitle: '',
      courseDescription: '',
      courseDuration: 0,
      courseNumberOfLectures: 0,
      courseStudents: [],
      courseNumberOfStudents: 0,
      courseContentUrl: '',
      courseOverviewUrl: '',
      courseThumbnail: '',
      innerPage: InnerPage,
      courseDetails: CourseDetails,

      externalUrl: '', // Replace with your actual URL
      externalHtmlContent: '',
      loading: true,

      headers: {
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, authorization, Verification"
      },

      storedPhoneNumber: '',
      storedPassword: '',
      storedStudentId: '',

      studentEnrolled: false,
      firstButtonLabel: 'Enroll Now',
      secondButtonLabel: 'Enrolling...',
      enrolling: false,
    };
  },
  methods: {
    setProgress(progress) {
      console.log(progress);
      // prints a float value between 0-100 when the element is scrolled
    },
    isStudentEnrolled() {
      //console.log('>>>>>>>>> Number of students: '+this.courseNumberOfStudents);
      if (this.courseNumberOfStudents > 0) {
        console.log('>>>>>>>>> Number of students: ' + this.courseNumberOfStudents);
        this.studentEnrolled = this.courseStudents.indexOf(this.storedStudentId) !== -1;
      }
    },
    enrollStudent() {
      this.enrolling = true;
      axios.put(`/api/courses/${this.courseId}/enroll/${this.storedStudentId}`).then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.studentEnrolled = true;
        }
        this.enrolling = false;
      })
    },
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}hr ${remainingMinutes}min`;
    },
    async fetchCourseDetails() {
      try {
        console.log('got in here');
        axios.get(`/api/courses/${this.courseId}`).then(response => {
          this.course = response.data;
          this.courseTags = this.course.tags;
          this.courseTitle = this.course.title;
          this.courseDescription = this.course.description;
          this.courseDuration = this.course.courseDuration;
          this.courseNumberOfLectures = this.course.numberOfLectures;
          this.courseStudents = this.course.students;
          this.courseNumberOfStudents = this.course.students.length;
          this.courseContentUrl = this.course.contentUrl;
          this.courseOverviewUrl = this.course.overviewUrl;
          this.courseThumbnail = this.course.thumbnail;
          this.fetchExternalHtmlContent();
          this.isStudentEnrolled();
        })

      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    },
    formatDate(dateString) {
      // Add your date formatting logic here
      return new Date(dateString).toLocaleDateString();
    },
    async fetchExternalHtmlContent() {
      try {
        //console.log('>>>>> This is the page: '+this.courseOverviewUrl);
        axios.get(this.courseContentUrl).then(response => {
          //console.log('>>>>> Response: '+response.data);
          this.externalHtmlContent = response.data;
        })

        //console.log('html page: '+this.externalHtmlContent);
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.storedPhoneNumber = localStorage.getItem("phoneNumber");
    this.storedPassword = localStorage.getItem("password");
    this.storedStudentId = localStorage.getItem("studentId");
    // Get courseId from route params
    this.courseId = this.$route.params.courseId;

    //route to login if not logged in
    if(!this.storedPhoneNumber)
    this.$router.push("/login");
    else 
    if(this.storedPhoneNumber.length==0)
    this.$router.push("/login");
    // Fetch course details
    this.fetchCourseDetails();
  },
};
</script>

<style>
.scrollable-wrapper {
  margin: 1rem;
  border: 1px solid black;
  display: flex;
  flex: 1 1 auto;
  max-height: 500px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.tall {
  height: 3000px;
}
</style>

<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutsindex></layoutsindex>
    <homebanner></homebanner>
    <!--featurecourse></featurecourse>
    <trendingcourse></trendingcourse>
    <shareknowledge></shareknowledge>
    <latestblog></latestblog-->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {};
  },
  name: "index",
};
</script>

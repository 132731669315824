// authService.js

import axios from 'axios';

const authService = {
  async checkSession() {
    try {
      const token = this.getToken();
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await axios.get('/api/check-session', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.message;
    } catch (error) {
      console.error(error.message);
      throw new Error('Session check failed');
    }
  },

  setToken(token) {
    localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  removeToken() {
    localStorage.removeItem('token');
  },

  // Add other authentication-related methods as needed
};

export default authService;

<template>
  <div class="home-five">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layoutsindex></layoutsindex>
      <homebannerfour></homebannerfour>
      <counterfour></counterfour>
      <experiencecourse></experiencecourse>
      <testimonialsfour></testimonialsfour>
      <footerfour></footerfour>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  name: "index-four",
};
</script>

<template>
  <div id="app">
    <router-view />
    <div id="recaptcha-container"></div>
  </div>
</template>
<script>
import LanguageSwitcher from './components/languageswitcher.vue'

export default {
  components: {
    LanguageSwitcher
  }
}
</script>
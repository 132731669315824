<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">

    <layouts2></layouts2>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container">
        <div class="row">

          <studentsidebar :fullName="fullName" :profilePictureUrl="profilePictureUrl"></studentsidebar>

          <!-- Profile Details -->
          <div class="col-xl-9 col-md-8">
            <b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.message
              }}</b-alert>

            <b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.message
              }}</b-alert>
            <div v-if="uploading" class="progress-bar-container">
              <div :style="{ 'width': progress + '%' }" class="progress-bar"></div>
              <div :style="{ 'width': progress + '%' }" class="progress-bar"></div>
            </div>
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>{{ $t('deleteAccountTitle') }}</h3>
                  <p>{{ $t('deleteAccountDescription') }}</p>
                </div>
                <div class="checkout-form personal-address">
                  <div class="personal-info-head">
                    <h4>{{ $t('warningTitle') }}</h4>
                    <p>{{ $t('warningDescription') }}</p>
                  </div>
                  <div class="un-subscribe p-0">
                    <button @click="showDeleteConfirmation" class="btn btn-danger" :disabled="uploading">{{
                      $t('deleteAccountButton') }}</button>
                  </div>
                  <div v-if="showModal" style="margin-top: 20px;">
                    <div class="modal-content">
                      <p><i class="fas fa-exclamation-triangle" style="font-size: 32px; color: orangered;"></i></p>
                      <div style="line-height: 0%;">
                        <p>{{ $t('confirmationMessage1') }}</p>
                        <p>{{ $t('confirmationMessage2') }}</p>
                      </div>
                      <div class="form-group">
                        <label for="password">{{ $t('passwordLabel') }}
                          <span class="text-danger">*</span>
                        </label>
                        <div class="input-group input-group-merge">
                          <input v-model="password" name="password" type="password" class="form-control"
                            :placeholder="$t('passwordPlaceholder')" />
                        </div>
                      </div>
                      <div class="profile-share-new">
                        <button @click="checkPassword" class="btn btn-success" :disabled="uploading">{{
                          $t('confirmButton') }}</button>
                        <button @click="cancelDelete" class="btn btn-cancel" :disabled="uploading">{{ $t('cancelButton')
                          }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile Details -->

        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->

    <layouts1></layouts1>

  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import Vue from "vue";
import axios from "axios"
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  sameAs,
  maxLength,
  numeric,
} from '@vuelidate/validators'
export default {
  components: {},
  data() {
    return {
      selectedCountry: '',
      countries: [
        { code: '1', name: 'Addis Abeba' },
        { code: '2', name: 'Afar' },
        { code: '3', name: 'Amhara' },
        { code: '4', name: 'Benishangul-Gumuz' },
        { code: '5', name: 'Central Ethiopia' },
        { code: '6', name: 'Dire Dawa' },
        { code: '7', name: 'Gambela' },
        { code: '8', name: 'Harari' },
        { code: '9', name: 'Oromia' },
        { code: '10', name: 'Sidama' },
        { code: '11', name: 'Somali' },
        { code: '12', name: 'South Ethiopia' },
        { code: '13', name: 'South West Ethiopia' },
        { code: '14', name: 'Tigray' },
        // Add more countries as needed
      ],
      isLoggedIn: false,
      firstName: '',
      fatherName: '',
      selectedImage: null,
      apiResponse: null,

      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
      isLoading: false,
      error: '',


      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      submit: false,
      submitted: false,
      formsubmit: false,
      typesubmit: false,

      showSuccessDialog: false,

      message: "",
      studentId: "",
      uploading: false,

      name: '',
      fathersName: '',
      grandFathersName: '',

      fullName: '',
      profilePictureUrl: '',
      user: null,
      region: '',

      showModal: false,
      progress: 0,
      password: ''

    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      name: { required, minLength: minLength(2) },
      fathersName: { required, minLength: minLength(2) },
      grandFathersName: { required, minLength: minLength(2) },
      //confirmPassword: sameAs(this.password)
    }
  },
  mounted() {
    this.storedPhoneNumber = localStorage.getItem("phoneNumber");
    this.storedStudentId = localStorage.getItem("studentId");
    if (this.storedPhoneNumber)
      if (this.storedPhoneNumber.length > 0)
        this.isLoggedIn = true;

    // Retrieve the user data from localStorage and parse it
    const userData = localStorage.getItem('userData');

    if (userData) {
      this.user = JSON.parse(userData);

      this.fullName = this.user.fullName;
      if (this.user.name)
        this.name = this.user.name
      if (this.user.fathersName)
        this.fathersName = this.user.fathersName
      if (this.user.grandFathersName)
        this.grandFathersName = this.user.grandFathersName

      if (this.user.profilePicture) {
        if (this.user.profilePicture.length > 0)
          this.profilePictureUrl = this.user.profilePicture;
        else
          this.profilePictureUrl = 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e'
      }
      else
        this.profilePictureUrl = 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e'

      if (this.user.region)
        this.selectedCountry = this.user.region;
    }
    //console.log('>>>>>>> fullName: ' + this.user.fullName);
    if (this.name.length == 0)
      this.splitName()

    this.progress = 0;

    //window.addEventListener("scroll", this.handleScroll);
    this.scrollToTop()
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset;

      if (scroll >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    async handleSubmit(e) {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        //alert('Error')
        return
      }
      else
        this.updateProfile()
    },
    generateRandomNumber() {
      // Generate a random integer not greater than 50
      return Math.floor(Math.random() * 50); // 0 to 49 (inclusive)
    },
    checkPassword() {
      if (this.password === this.user.password) {
        //this.deleteAccount()
        console.log('Deleting...');
        this.confirmDelete();
      }
      else {
        this.isRegisterError = true;
        this.message = 'Password incorrect. Please check again.';
      }
    },
    showMessageDialog() {
      this.progress = 100;
      setTimeout(() => {
        this.uploading = false;
        this.showSuccessDialog = !this.isRegisterError
      }
        , 2000);
    },
    showDeleteConfirmation() {
      this.showModal = true;
      console.log('Delete?')
    },
    async confirmDelete() {
      // Perform the delete action here
      console.log('Deleting...');
      this.uploading = true;
      this.progress = this.generateRandomNumber();
      this.scrollToTop();
      try {
        await axios.post('/api/users/' + this.storedStudentId + '/delete-account',
          {
            onUploadProgress: (progressEvent) => {
              // Update the upload progress value
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
            onDownloadProgress: (progressEvent) => {
              // Update the upload progress value
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
          }).then((response) => {
            //this.error=response.data.error;

            this.isLoading = false;
            console.log(response.data.message);
            this.message = response.data.message;
            this.isRegisterError = response.data.error;
            this.tryingToRegister = false;
            this.showMessageDialog();

            //get user data
            //localStorage.setItem('userData', JSON.stringify(response.data));
            //console.log('>>>>>>>>>>>> User Data: ' + JSON.stringify(response.data))
            this.$router.push("/login");
          })

      } catch (error) {
        console.log("There is an error: " + error);
        this.message = 'Profile picture deletion failed. Please try again.';
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
        this.uploading = false;
      }
      this.showModal = false; // Close the modal after confirmation
    },
    cancelDelete() {
      this.showModal = false; // Close the modal if the user cancels
    },
    splitName() {
      const names = this.fullName.split(' ');
      this.name = names[0] || '';
      this.fathersName = names.slice(1, -1).join(' ').trim() || '';
      this.grandFathersName = names[names.length - 1] || '';
      if (this.fathersName.length == 0) {
        this.fathersName = this.grandFathersName;
        this.grandFathersName = '';
      }
    },
    handleFileChange(event) {
      // Handle the file selection and preview the image
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async uploadProfilePicture() {
      //try {
      this.uploading = true;
      this.progress = this.generateRandomNumber();
      this.scrollToTop();
      // Convert the base64 data URI to a Blob
      const blob = await this.dataURItoBlob(this.selectedImage);

      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('profilePicture', blob, 'profilePicture.png');

      //const response = await axios.post('/api/users/' + this.storedStudentId + '/upload', formData);

      try {
        await axios.post('/api/users/' + this.storedStudentId + '/upload', formData,
          {
            onUploadProgress: (progressEvent) => {
              // Update the upload progress value
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },
            onDownloadProgress: (progressEvent) => {
              // Update the upload progress value
              this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
            },

          }
        ).then((response) => {
          this.apiResponse = response.data.message;
          this.isLoading = false;
          console.log(response.data.message);
          this.message = response.data.message;
          this.studentId = response.data.studentId;
          this.verified = response.data.verified;
          this.isRegisterError = response.data.error;
          this.tryingToRegister = false;
          this.showMessageDialog();
          this.selectedImage = null;
          //get user data
          localStorage.setItem('userData', JSON.stringify(response.data));
          //console.log('>>>>>>>>>>>> User Data: ' + JSON.stringify(response.data))
        })

      } catch (error) {
        console.error('Profile picture upload failed:', error.message);
        this.message = 'Profile picture upload failed. Please try again.';
        this.uploading = false;
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
      }
      /* 
      this.apiResponse = response.data.message;
      this.isLoading = false;
      console.log(response.data.message);
      this.message = response.data.message;
      this.studentId = response.data.studentId;
      this.verified = response.data.verified;
      this.isRegisterError = response.data.error;
      this.tryingToRegister = false;
      this.showSuccessDialog = !this.isRegisterError;
      this.uploading = false;
    } catch (error) {
      console.error('Profile picture upload failed:', error.message);
      this.uploading = false;
      this.isLoading = false;
      this.tryingToRegister = false;
      this.isRegisterError = true;
    } */
    },
    dataURItoBlob(dataURI) {
      // Convert a data URI to a Blob
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: mimeString });
    },
    async updateProfile() {
      this.uploading = true;
      this.progress = this.generateRandomNumber();
      this.scrollToTop();
      try {
        await axios.put('/api/users/' + this.storedStudentId + '/update-user-details', {
          name: this.name,
          fathersName: this.fathersName,
          grandFathersName: this.grandFathersName,
          region: this.selectedCountry,
          onUploadProgress: (progressEvent) => {
            // Update the upload progress value
            this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
          },
          onDownloadProgress: (progressEvent) => {
            // Update the upload progress value
            this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
          },
        }).then((response) => {
          //this.error=response.data.error;

          this.isLoading = false;
          console.log(response.data.message);
          this.message = response.data.message;
          this.isRegisterError = response.data.error;
          this.tryingToRegister = false;
          this.showMessageDialog();


          //get user data
          localStorage.setItem('userData', JSON.stringify(response.data));
          //console.log('>>>>>>>>>>>> User Data: ' + JSON.stringify(response.data))
        })

      } catch (error) {
        console.log("There is an error: " + error);
        this.message = 'Error updating profile. Please try again.';
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
        this.uploading = false;
      }
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: Adds a smooth scrolling effect
      });
    },
  }
};
</script>

<style>
/* Style the modal to make it visually appealing */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-form .modal-content {
  background: transparent;
  color: #666666;
  border: 1px solid orangered;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 0;
  padding: 10px 10px;
  font-weight: 100;
  border-radius: 5px;
  font-size: 12px;
  transition: transform 330ms ease-in-out;
}

.checkout-form .modal-content .btn-success {
  background: transparent;
  color: #666666;
  border: 1px solid #666666;
  margin-right: 10px;
  border-radius: 0;
  padding: 7px 22px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.checkout-form .modal-content .btn-success:hover {
  background: orangered;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.checkout-form .modal-content .btn-cancel {
  background: transparent;
  color: #666666;
  border: 1px solid #666666;
  margin-right: 10px;
  border-radius: 0;
  padding: 7px 22px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.checkout-form .modal-content .btn-cancel:hover {
  background: #666666;
  color: #FFFFFF;
  border: 1px solid #666666;
}

/* Style for the progress bar container */
.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #eee;
  border-radius: 3px;
  margin-bottom: 10px;
  overflow: hidden;
}

/* Style for the progress bar */
.progress-bar-container .progress-bar {
  height: 100%;
  background-color: #4caf50;
  /* Green color */
  transition: width 0.3s ease;
  /* Add a smooth transition effect */
  border-radius: 3px;
}
</style>
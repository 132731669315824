<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <logo-with-language />
            <h1>{{ $t('loginLink') }}</h1>
            <b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.message }}</b-alert>

            <b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.message }}</b-alert>
            <form @submit.prevent="handleSubmit" class="signin">

              <div class="form-group">
                <label for="phoneNumber">{{ $t('phoneNumberLabel') }}
                  <span class="text-danger">{{ $t('requiredAsterisk1') }}</span>
                </label>
                <div class="input-group input-group-merge">
                  <input v-model="phoneNumber" name="phoneNumber" type="phone" class="form-control"
                    :placeholder="$t('phoneNumberPlaceholder')" :class="{
                      'is-invalid': submitted && v$.phoneNumber.$error,
                    }" @blur="v$.phoneNumber.$touch" />
                </div>
                <div v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
                  <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                </div>
              </div>

              <div class="form-group">
                <label for="password">{{ $t('passwordLabel') }}
                  <span class="text-danger">{{ $t('requiredAsterisk2') }}</span>
                </label>
                <div class="input-group input-group-merge">
                  <input v-model="password" name="password" type="password" class="form-control"
                    :placeholder="$t('passwordPlaceholder')" :class="{
                      'is-invalid': submitted && v$.password.$error,
                    }" @blur="v$.password.$touch" />
                </div>
                <div v-for="error of v$.password.$errors" :key="error.$uid">
                  <span class="text-danger" style="font-size: x-small;">{{ error.$message }}</span>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">{{ getButtonLabel()
                  }}</button>
              </div>
              <div class="form-group ">
                <p class="mt-2">
                  <router-link to="forgot-password">{{ $t('forgotPasswordLink') }}</router-link>
                </p>
              </div>

            </Form>

          </div>

          <div class="google-bg text-center">
            <!--span><a href="javascript:void(0);">Or sign in with</a></span>
            <div class="sign-google">
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/net-icon-01.png"
                      class="img-fluid"
                      alt="Logo"
                    />
                    Sign In using Google</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/net-icon-02.png"
                      class="img-fluid"
                      alt="Logo"
                    />Sign In using Facebook</a
                  >
                </li>
              </ul>
            </div-->
            <p class="mb-0">
              {{ $t('newUserPrompt') }} <router-link to="register">{{ $t('createAccountLink') }}</router-link>
            </p>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { Form, Field } from "vee-validate";
import { router } from "@/router";
import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  sameAs,
  maxLength,
  numeric,
} from '@vuelidate/validators'
import authService from "@/authService";

export default {
  data() {
    return {

      fullName: "",
      phoneNumber: "",
      password: "",
      verified: false,
      //confirmPassword: "",

      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
      isLoading: false,
      error: '',


      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      submit: false,
      submitted: false,
      formsubmit: false,
      typesubmit: false,

      showSuccessDialog: false,

      message: "",
      studentId: "",
      userData: []
    };
  },
  validations() {
    return {
      phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      password: { required, minLength: minLength(6) },
      //confirmPassword: sameAs(this.password)
    }
  },
  components: {
    Form,
    Field,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  methods: {
    async handleSubmit(e) {
      this.submitted = true;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        //alert('Error')
        return
      }
      else
        this.signin()
    },

    getButtonLabel() {
      if (!this.isLoading)
        return this.$t('loginButtonLabel');
      else
        return this.$t('loggingInButtonLabel');
    },

    /* ß */

    saveUserInfo() {
      localStorage.setItem("phoneNumber", this.phoneNumber);
      localStorage.setItem("password", this.password);
      //localStorage.setItem("studentId", this.studentId);
    },

    async signin() {
      this.isLoading = true;
      this.error = '';
      this.tryingToRegister = true;
      var responseText = "";
      //console.log(this.phoneNumber, this.password);

      try {
        await axios.post('/api/users/login', {
          phoneNumber: this.phoneNumber,
          password: this.password,
        }).then((response) => {
          //this.error=response.data.error;
          this.userData = response.data;
          //console.log('>>>>>> userData: '+this.userData)
          //this.isLoading = false;
          //console.log(this.userData[1].message);
          this.message = this.userData[1].message;
          this.studentId = this.userData[0].id;
          this.verified = this.userData[0].verified;
          this.isRegisterError = this.userData[1].error;
          this.tryingToRegister = false;
          this.showSuccessDialog = !this.isRegisterError;
          //console.log('>>>>>> User Data: '+JSON.stringify(this.userData[0]))

          if (this.showSuccessDialog) {
            localStorage.setItem("verificationNumber", this.phoneNumber);
            localStorage.setItem("studentId", this.studentId);
            localStorage.setItem("forgotPassword", false);
            //save user data in localstorage
            localStorage.setItem('userData', JSON.stringify(this.userData[0]));
            if (this.verified) {
              this.saveUserInfo();
              authService.setToken(this.userData[1].token);
              setTimeout(() => this.$router.push("/"), 2000);
            }
            else
              setTimeout(() => this.$router.push("/verify"), 2000);
          }
          //this.$router.push("/login");
        })

        // Clear form and show success message
        /* this.fullName = '';
        this.phoneNumber = '';
        this.password = '';
        this.confirmPassword = ''; */
      } catch (error) {
        console.log("There is an error: " + error);
        this.isLoading = false;
        this.tryingToRegister = false;
        this.isRegisterError = true;
      }
    },

    setLanguage(lang) {
      this.$i18n.locale = lang
    }


  },
};
</script>
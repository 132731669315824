<template>
  <div>
    <div class="container">
      <ul class="nav header-navbar-rht">
        <li class="nav-item user-nav">
          <div class="language-switch">
            <span 
              @click="setLanguage('am')" 
              :class="{ active: isAmharicActive }" 
              class="switch-option"
            >
              Am
            </span>
            <span 
              @click="setLanguage('en')" 
              :class="{ active: isEnglishActive }" 
              class="switch-option"
            >
              En
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      showDropdown: false,
      languages: {
        am: 'አማርኛ',
        en: 'English'
      },
      globe: require('@/assets/img/icon/language.svg')
    }
  },
  computed: {
    isEnglishActive() {
      return this.$i18n.locale === 'en';
    },
    isAmharicActive() {
      return this.$i18n.locale === 'am';
    }
  },
  methods: {
    setLanguage(locale) {
      this.$i18n.locale = locale;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    }
  }
}
</script>


<style>
.language-switch {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px;
}

.switch-option {
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  font-weight: bold;
}

.switch-option.active {
  background-color: #007bff;
  color: white;
}

.switch-option:hover {
  background-color: #007bff;
  color: white;
}

</style>

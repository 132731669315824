<template>
  <div class="home-two">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layoutsindex></layoutsindex>
      <homebannertwo></homebannertwo>
      <coursetwo></coursetwo>
      <testimonialtwo></testimonialtwo>
      <featuretwo></featuretwo>
      <footertwo></footertwo>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  name: "index-two",
};
</script>

<template>
  <div class="tab-content">
    <!-- All -->
    <div class="tab-pane fade active show" id="alltab" role="tabpanel">
      <div class="all-course">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /All -->

    <!-- Most popular -->
    <div class="tab-pane fade" id="mostpopulartab">
      <div class="all-course">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Most popular -->

    <!-- Business -->
    <div class="tab-pane fade" id="businesstab">
      <div class="businesstab">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Business -->

    <!-- Design -->
    <div class="tab-pane fade" id="designtab">
      <div class="designtab">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Design -->

    <!-- Music -->
    <div class="tab-pane fade" id="musictab">
      <div class="music-label">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Music -->

    <!-- Programmiing -->
    <div class="tab-pane fade" id="programmingtab">
      <div class="programmingtab">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML CSS
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML CSS
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Programmiing -->

    <!-- Database -->
    <div class="tab-pane fade" id="databasetab">
      <div class="databasetab">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-22.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user5.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML CSS
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-27.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user2.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Web Developer PHP Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>500 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-23.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user6.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Pyhton Development</p>
                      <h3 class="title instructor-text">
                        The Complete Pyhton Development Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>Free</h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Personalized Learning</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML CSS
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>450 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-21.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user4.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Creative Arts & media Course Completed
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>250 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$700 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-20.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user3.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        The Complete Business Management Course
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-24.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user7.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Business Management</p>
                      <h3 class="title instructor-text">
                        Build Websites with HTML5 CSS3 Javascript
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$650 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link to="course-details">
                    <img
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-25.jpg"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link to="instructor-profile"
                        ><img src="@/assets/img/user/user8.jpg" alt="" class="img-fluid"
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link to="course-details">
                      <p>Creative Arts & media</p>
                      <h3 class="title instructor-text">
                        Build Responsive Websites with HTML CSS
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>400 Students</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <h3>$300 <span>$99.00</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-clock me-2"></i>
                      <span>6hr 30min</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
    <!-- /Database -->
  </div>
</template>

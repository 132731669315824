<template>
  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <!-- Copyright -->
      <div class="copyright">
        <div class="row">
          <div class="col-md-6">
            <div class="privacy-policy">
              <ul>
                <li><router-link to="/term-condition">{{ $t('footerTermsLink') }}</router-link></li>
                <li><router-link to="/privacy-policy">{{ $t('footerPrivacyLink') }}</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="copyright-text">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} Mager. {{ $t('footerCopyrightText') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /Copyright -->
    </div>
  </div>
  <!-- /Footer Bottom -->
</template>

<template>
  <header v-bind:class="$route.meta.headerClass">
    <div>
      <nav class="navbar navbar-expand-lg scroll-sticky" v-bind:class="$route.meta.NavbarClass"
        :class="{ 'add-header-bg': isSticky }">
        <div class="container">
          <ul class="nav header-navbar-rht">
            <li>
              <a class="dropdown-item" @click="goHome" style="cursor: pointer;">
                <img :src="home" alt="Home" style="width: 24px; height: 24px;" />
              </a>
            </li>
            <li class="nav-item user-nav">
              <a href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown">
                <span>
                  <img :src="globe" alt="Language" style="width: 24px; height: 24px;" />
                </span>
              </a>
              <div class="users dropdown-menu dropdown-menu-right" data-popper-placement="bottom-end">

                <a class="dropdown-item" @click="setLanguage('am')" style="cursor: pointer;"><i
                    :class="{ 'feather-check me-1': isAmharicActive, 'feather-chevron-right me-1': !isAmharicActive }"></i>
                  አማርኛ
                </a>

                <a class="dropdown-item" @click="setLanguage('en')" style="cursor: pointer;"><i
                    :class="{ 'feather-check me-1': isEnglishActive, 'feather-chevron-right me-1': !isEnglishActive }"></i>
                  English
                </a>
              </div>
            </li>

          </ul>


        </div>
      </nav>
      <div class="sidebar-overlay" :class="{ opened: isSidebarOpen }" @click="closeSidebar"></div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false,
      languages: {
        am: 'አማርኛ',
        en: 'English'
      },

globe: require('@/assets/img/icon/language.svg'),
home: require('@/assets/img/icon/home.svg')
    }
  },
  computed: {
    isEnglishActive() {
      return this.$i18n.locale === 'en';
    },
    isAmharicActive() {
      return this.$i18n.locale === 'am';
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    setLanguage(locale) {
      this.$i18n.locale = locale
      this.showDropdown = false
    },
    goHome() {
      this.$router.push('/')
    },
  },
}
</script>

<style>
.parent-container {
  display: flex;
  justify-content: flex-end;
  /* Align child to the right */
  padding: 10px;
  /* Add some padding to the container */
  width: 100%;
}

.language-selector-container {
  display: flex;
  align-items: center;
}

.toggle-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  /* Remove padding */
}

.toggle-item.left {
  cursor: pointer;
  margin-right: 5px;
  /* Minimal margin */
}

.home-icon {
  width: 24px;
  height: 24px;
}

.language-selector {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.language-selector .globe-icon {
  width: 24px;
  height: 24px;
  display: block;
}

.language-selector .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  /* Ensure the dropdown is on top of other elements */
  width: max-content;
  /* Ensure the dropdown width fits its content */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
}

.language-selector .dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.language-selector .dropdown li:hover {
  background-color: #f0f0f0;
}

@media (max-width: 600px) {
  .language-selector .dropdown li {
    font-size: 12px;
    padding: 6px 10px;
  }
}
</style>

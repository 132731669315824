import { initializeApp } from "firebase/app"
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDYjS9etLY28PKZDCV9uiEmnO5HayR2kQQ", 
    authDomain: "mager-a0c81.firebaseapp.com", 
    projectId: "mager-a0c81",  
    storageBucket: "mager-a0c81.appspot.com",  
    messagingSenderId: "762948082100",  
    appId: "1:762948082100:web:c3a874d5f971418213bae1",
    measurementId: "G-9NXJWZNWKY"  
}

const firebase = initializeApp(firebaseConfig)

// const projectAuth = getAuth(app)
// const projectFirestore = getFirestore(app)

// export { projectAuth, projectFirestore }
// export default projectFirestore;
export {firebase}
<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>

    <termcondition></termcondition>
    <!-- Help Details -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="terms-content">
              <div class="terms-text">
                <h3>Effective date: <span>23rd of December, 2023</span></h3>

  <h4>1. Acceptance of Terms</h4>
  <p>By accessing and using the Mager platform, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please refrain from using the platform.</p>

  <h4>2. User Accounts and Security</h4>
  <ol>
    <li>To access certain features of Mager, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information and are liable for all activities that occur under your account.</li>
    <li>You agree to provide accurate and up-to-date information during the account registration process.</li>
  </ol>

  <h4>3. Course Enrollment</h4>
  <ol>
    <li>Mager offers a range of courses, some of which may be free, and others may require payment. The terms of payment, including fees and refund policies, are specified on the course enrollment page.</li>
    <li>Mager reserves the right to modify or discontinue any course without prior notice.</li>
  </ol>

  <h4>4. Content Ownership</h4>
  <ol>
    <li>The content provided on the Mager platform, including courses, text, graphics, images, and software, is the property of Mager or its content suppliers.</li>
    <li>Users may not reproduce, distribute, or modify any content from the platform without the express consent of Mager.</li>
  </ol>

  <h4>5. Code of Conduct</h4>
  <ol>
    <li>Users agree not to engage in any activity that may disrupt the proper functioning of the platform or compromise the security of other users.</li>
    <li>Mager may terminate or suspend user accounts that violate the platform's code of conduct.</li>
  </ol>

  <h4>6. Privacy Policy</h4>
  <p>Mager respects user privacy. The collection and use of personal information are governed by the <a href="/privacy-policy">Mager Privacy Policy</a>.</p>

  <h4>7. Limitation of Liability</h4>
  <ol>
    <li>Mager is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the platform.</li>
    <li>Mager does not guarantee the accuracy, completeness, or reliability of any content on the platform.</li>
  </ol>

  <h4>8. Governing Law</h4>
  <p>These terms and conditions are governed by the laws of Ethiopia. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in Ethiopia.</p>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Help Details -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

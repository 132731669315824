<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <!--coursedetails></coursedetails-->
    <component :is="courseDetails" :courseTitle="courseTitle" />
    <!--inner-page></inner-page-->
    <component :is="innerPage" :courseId="courseId" :courseTags="courseTags" :courseTitle="courseTitle"
      :courseDescription="courseDescription" :courseDuration="courseDuration"
      :courseNumberOfLectures="courseNumberOfLectures" :courseNumberOfStudents="courseNumberOfStudents"
      :courseContentUrl="courseContentUrl" :courseOverviewUrl="courseOverviewUrl" :courseThumbnail="courseThumbnail" />
    <!--iframe :src="courseOverviewUrl" width="100%" height="100%" frameborder="0"></iframe-->
    <!--div v-if="loading">Loading...</div>
    <div v-else v-html="externalHtmlContent"></div-->


    <!-- Course Content -->
    <section class="page-content course-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">

            <!--div v-if="loading"  class="card overview-sec">
          <div class="card-body">
            <i class="fas fa-spinner fa-spin"></i><span class="subs-title">&nbsp;&nbsp;Loading course overview ...</span>
          </div>
        </div-->
            <code-loader v-if="loading" :speed="2" :animate="true"></code-loader>
            <div v-else v-html="externalHtmlContent"></div>

          </div>

          <div class="col-lg-4">
            <div class="sidebar-sec stickysidebar">
              <!-- Video -->
              <div class="video-sec vid-bg">
                <div class="card">
                  <div class="card-body">
                    <!--a
                  href="https://www.youtube.com/embed/1trvO6dqQUI"
                  class="video-thumbnail"
                  data-fancybox=""
                >
                  <div class="play-icon">
                    <i class="fa-solid fa-play"></i>
                  </div-->
                    <facebook-loader v-if="loading" :speed="2" :animate="true"></facebook-loader>
                    <img v-else class="" :src="courseThumbnail" alt="" style="border-radius: 8px;" />
                    <!--/a-->
                    <div class="video-details">
                      <!--div class="course-fee">
                    <h2>FREE</h2>
                    <p><span>$99.00</span> 50% off</p>
                  </div>
                  <div class="row gx-2">
                    <div class="col-md-6">
                      <router-link to="course-wishlist" class="btn btn-wish w-100"
                        ><i class="feather-heart"></i> Add to Wishlist</router-link
                      >
                    </div>
                    <div class="col-md-6">
                      <a href="javascript:;" class="btn btn-wish w-100"
                        ><i class="feather-share-2"></i> Share</a
                      >
                    </div>
                  </div-->
                      <div v-if="this.storedPhoneNumber.length > 0">
                        <div v-if="studentEnrolled">
                          <router-link :to="{ name: 'course-details', params: { courseId: courseId } }"
                            class="btn btn-enroll w-100">{{ $t('goToCourse') }}</router-link>
                        </div>
                        <div v-else>
                          <button type="button" @click="enrollStudent()" to="enrollStudent" :disabled="enrolling"
                            class="btn btn-enroll w-100">{{ getButtonLabel() }}</button>
                        </div>

                      </div>
                      <div v-else>
                        <router-link to="/register" class="btn btn-enroll w-100">{{ $t('registerToEnroll')
                          }}</router-link>
                      </div>
                      <!--router-link v-if="this.storedPhoneNumber!=null" to="checkout" class="btn btn-enroll w-100"
                    >Enroll Now</router-link>
                  <router-link v-else to="/register" class="btn btn-enroll w-100"
                    >Register To Enroll</router-link-->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Video -->

              <!-- Include -->
              <!--div class="card include-sec">
            <div class="card-body">
              <div class="cat-title">
                <h4>Includes</h4>
              </div>
              <ul>
                <li>
                  <img
                    src="@/assets/img/icon/import.svg"
                    class="me-2"
                    alt=""
                  />
                  11 hours on-demand video
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/play.svg"
                    class="me-2"
                    alt=""
                  />
                  69 downloadable resources
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/key.svg"
                    class="me-2"
                    alt=""
                  />
                  Full lifetime access
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/mobile.svg"
                    class="me-2"
                    alt=""
                  />
                  Access on mobile and TV
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/cloud.svg"
                    class="me-2"
                    alt=""
                  />
                  Assignments
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/teacher.svg"
                    class="me-2"
                    alt=""
                  />
                  Certificate of Completion
                </li>
              </ul>
            </div>
          </div-->
              <!-- /Include -->

              <!-- Features -->
              <div class="card feature-sec">
                <div class="card-body">
                  <div class="cat-title">
                    <h4>{{ $t('aboutCourse') }}</h4>
                  </div>
                  <ul>
                    <li>
                      <img src="@/assets/img/icon/users.svg" class="me-2" alt="" />
                      {{ $t('enrolledLabel') }} <span>{{ courseNumberOfStudents }} {{ $t('studentsEnrolled') }}</span>
                    </li>
                    <li>
                      <img src="@/assets/img/icon/timer.svg" class="me-2" alt="" />
                      {{ $t('durationLabel') }} <span>{{ formatDuration(courseDuration) }}</span>
                    </li>
                    <li>
                      <img src="@/assets/img/icon/chapter.svg" class="me-2" alt="" />
                      {{ $t('lecturesLabel') }} <span>{{ courseNumberOfLectures }}</span>
                    </li>
                    <!--li>
                  <img
                    src="@/assets/img/icon/video.svg"
                    class="me-2"
                    alt=""
                  />
                  Video:<span> 12 hours</span>
                </li>
                <li>
                  <img
                    src="@/assets/img/icon/chart.svg"
                    class="me-2"
                    alt=""
                  />
                  Level: <span>Beginner</span>
                </li-->
                  </ul>
                </div>
              </div>
              <!-- /Features -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- /Pricing Plan -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import InnerPage from './inner-page.vue';
import CourseDetails from '../../../../components/breadcrumb/coursedetails.vue';
import axios from 'axios';
import { CodeLoader, FacebookLoader } from 'vue-content-loader';


export default {
  components: {
    CodeLoader, FacebookLoader
  },
  data() {
    return {
      courseId: '',
      course: null,
      courseTags: [],
      courseTitle: '',
      courseDescription: '',
      courseDuration: 0,
      courseNumberOfLectures: 0,
      courseStudents: [],
      courseNumberOfStudents: 0,
      courseContentUrl: '',
      courseOverviewUrl: '',
      courseThumbnail: '',
      innerPage: InnerPage,
      courseDetails: CourseDetails,

      externalUrl: '', // Replace with your actual URL
      externalHtmlContent: '',
      loading: true,

      headers: {
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, authorization, Verification"
      },

      storedPhoneNumber: '',
      storedPassword: '',
      storedStudentId: '',

      studentEnrolled: false,
      firstButtonLabel: 'Enroll Now',
      secondButtonLabel: 'Enrolling...',
      enrolling: false,
    };
  },
  methods: {

    getButtonLabel() {
      if (this.enrolling)
        return this.$t('enrolling');
      else
        return this.$t('enrollNow');
    },

    isStudentEnrolled() {
      //console.log('>>>>>>>>> Number of students: '+this.courseNumberOfStudents);
      if (this.courseNumberOfStudents > 0) {
        console.log('>>>>>>>>> Number of students: ' + this.courseNumberOfStudents);
        this.studentEnrolled = this.courseStudents.indexOf(this.storedStudentId) !== -1;
      }
    },
    enrollStudent() {
      this.enrolling = true;
      axios.put(`/api/courses/${this.courseId}/enroll/${this.storedStudentId}`).then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.studentEnrolled = true;
        }
        this.enrolling = false;
      })
    },
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}hr ${remainingMinutes}min`;
    },
    async fetchCourseDetails() {
      try {
        console.log('got in here');
        axios.get(`/api/courses/${this.courseId}`).then(response => {
          this.course = response.data;
          this.courseTags = this.course.tags;
          this.courseTitle = this.course.title;
          this.courseDescription = this.course.description;
          this.courseDuration = this.course.courseDuration;
          this.courseNumberOfLectures = this.course.numberOfLectures;
          this.courseStudents = this.course.students;
          this.courseNumberOfStudents = this.course.students.length;
          this.courseContentUrl = this.course.contentUrl;
          this.courseOverviewUrl = this.course.overviewUrl;
          this.courseThumbnail = this.course.thumbnail;
          this.fetchExternalHtmlContent();
          this.isStudentEnrolled();
        })

      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    },
    formatDate(dateString) {
      // Add your date formatting logic here
      return new Date(dateString).toLocaleDateString();
    },
    async fetchExternalHtmlContent() {
      try {
        //console.log('>>>>> This is the page: '+this.courseOverviewUrl);
        axios.get(this.courseOverviewUrl).then(response => {
          //console.log('>>>>> Response: '+response.data);
          this.externalHtmlContent = response.data;
        })

        //console.log('html page: '+this.externalHtmlContent);
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (localStorage.getItem("phoneNumber") != null) {
      this.storedPhoneNumber = localStorage.getItem("phoneNumber");
      this.storedPassword = localStorage.getItem("password");
      this.storedStudentId = localStorage.getItem("studentId");

    }
    this.firstButtonLabel = this.$t('enrollNow');
    this.secondButtonLabel = this.$t('enrolling');
    // Get courseId from route params
    this.courseId = this.$route.params.courseId;
    // Fetch course details
    this.fetchCourseDetails();
  },
};
</script>

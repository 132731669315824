<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <logo-with-language />
            <h1>{{ $t('verifyAccountTitle') }}</h1>
            <b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.error }}</b-alert>
            <b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.successMessage }}</b-alert>
            <form @submit.prevent="handleSubmit" class="signin">

              <div v-if="!verificationCodeSent">
                <div class="form-group">
                  <label for="phoneNumber">{{ $t('phoneNumberLabel') }}
                    <span class="text-danger">{{ $t('requiredAsterisk1') }}</span>
                  </label>
                  <div class="input-group input-group-merge">
                    <input v-model="phoneNumber" type="phone" readonly class="form-control"
                      :placeholder="$t('phoneNumberPlaceholder')" />
                  </div>
                </div>
                <div class="form-group mb-4 text-center">
                  <button class="btn btn-primary btn-block" :disabled="disableSendCodeButton" @click="sendOTP">{{
                    $t('sendCodeButton') }}</button>
                </div>
              </div>

              <div v-if="verificationCodeSent">
                <div class="form-group">
                  <label for="otp">{{ $t('verificationCodeLabel') }}
                    <span class="text-danger">{{ $t('requiredAsterisk2') }}</span>
                  </label>
                  <div class="input-group input-group-merge">
                    <input v-model="otp" name="otp" type="text" class="form-control"
                      :placeholder="$t('verificationCodePlaceholder')" />
                  </div>
                </div>
                <div class="form-group mb-0 text-center">
                  <button class="btn btn-primary btn-block" :disabled="disableVerifyButton" @click="verifyOTP">{{
                    $t('verifyCodeButton') }}</button>
                </div>
              </div>

              <!--div id="recaptcha-container"></div-->

            </form>
          </div>
          <div class="google-bg text-center">
            <!--span><a href="javascript:void(0);">Or sign in with</a></span>
            <div class="sign-google">
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/net-icon-01.png"
                      class="img-fluid"
                      alt="Logo"
                    />
                    Sign In using Google</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/net-icon-02.png"
                      class="img-fluid"
                      alt="Logo"
                    />Sign In using Facebook</a
                  >
                </li>
              </ul>
            </div-->
            <p class="mb-0">
              {{ $t('newUserPrompt') }} <router-link to="register">{{ $t('createAccountLink') }}</router-link> or
              <router-link to="login">{{ $t('loginLink') }}</router-link>
            </p>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { Form, Field } from "vee-validate";
import axios from "axios";
import firebase from 'firebase/app';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier, confirm } from "firebase/auth";
import { getFirestore, updateDoc, doc } from "firebase/firestore/lite"
//import firebaseConfig from '../../../firebaseConfig';
import { firebaseConfig } from "../../../firebase-int";
const auth = getAuth();
const firestore = getFirestore(firebaseConfig);
export default {

  data() {
    return {

      phoneNumber: "",
      studentId: "",
      verificationCodeSent: false,
      //confirmPassword: "",

      showPassword: false,
      validationError: 0,
      strength: "",
      errors: {
        password: "",
      },
      isLoading: false,
      error: '',


      regError: null,
      tryingToRegister: false,
      isRegisterError: false,

      submit: false,
      submitted: false,
      formsubmit: false,
      typesubmit: false,

      showSuccessDialog: false,

      message: "",
      studentId: "",
      forgotPassword: false,

      sendVerificationDisabled: 0,
      verifyCodeDisabled: 1,

      email: '',
      error: null,
      tryingToReset: false,
      isVerificationError: false,
      isSuccess: false,
      successMessage: null,

      appVerifier: '',
      otp: '',

      disableSendCodeButton: false,
      disableVerifyButton: false
    };
  },
  components: {
    Form,
    Field,
  },
  mounted() {
    this.phoneNumber = localStorage.getItem('verificationNumber');
    this.studentId = localStorage.getItem('studentId');
    this.forgotPassword = JSON.parse(localStorage.getItem('forgotPassword'));
    console.log('>>>>>>. Forgot Password: ' + this.forgotPassword)
    this.initReCaptcha();
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  //setup: () => ({ v$: useVuelidate() }),
  methods: {
    /*async sendOTP() {
      try {
        const app = firebase.initializeApp(firebaseConfig);
        const auth = app.auth();

        const confirmationResult = await auth.signInWithPhoneNumber(`+${this.phoneNumber}`, new firebase.auth.RecaptchaVerifier('recaptcha-container'));

        this.verificationCodeSent = true;
        this.confirmationResult = confirmationResult;
      } catch (error) {
        console.error(error.message);
      }
    },
    async verifyOTP() {
      try {
        const { confirmationResult, otp } = this;

        const userCredential = await confirmationResult.confirm(otp);
        this.user = userCredential.user;
      } catch (error) {
        console.error(error.message);
      }
    }*/
    sendOTP() {
      this.disableSendCodeButton = true;
      this.sendVerificationDisabled = 1;
      //
      let countryCode = '+251' //ethiopia
      let phoneNumber = countryCode + this.phoneNumber
      //let phoneNumber = countryCode + '912622985'
      //
      let appVerifier = this.appVerifier
      //
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          //
          this.verifyCodeDisabled = 0;
          this.showSuccessDialog = true;
          this.isRegisterError = false;
          this.successMessage = "Verification code sent to " + this.phoneNumber;
          this.isSuccess = true;
          this.verificationCodeSent = true;
          this.disableSendCodeButton = false;
          //alert('SMS sent to: ' + phoneNumber)
        }).catch((error) => {
          // Error; SMS not sent
          // ...
          this.isSuccess = false;
          this.verificationCodeSent = false;
          this.showSuccessDialog = false;
          this.isRegisterError = true;
          this.sendVerificationDisabled = 0;
          this.error = "Error sending verification code. " + error
          this.isVerificationError = true;
          this.disableSendCodeButton = false;
          //alert('Error ! SMS not sent: ' + error)
        });

    },
    //
    verifyOTP() {
      //
      let vm = this
      let code = this.otp

      this.disableVerifyButton = true;
      //
      window.confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
        var user = result.user;
        //console.log('>>>>>> User data: '+user)
        //alert('User verification was successful. Redirect user.')
        // ...
        //route to set password !

        console.log('>>>>>>. Forgot Password>>: ' + this.forgotPassword)
        //update user verified flag then route
        if (this.forgotPassword) {
          localStorage.setItem("resetPassword", true);
          this.isSuccess = true;
          this.successMessage = "Successful verification. Please reset your password.";
          setTimeout(() => { this.disableVerifyButton = false; this.$router.push("/reset-password") }, 1000);
        }
        else
          axios.put('/api/users/' + this.studentId + '/verify').then(reponse => {
            this.isSuccess = true;
            this.successMessage = reponse.data.message + " Please login.";
            setTimeout(() => { this.disableVerifyButton = false; this.$router.push("/login") }, 1000);
          });

      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        this.isRegisterError = true;
        this.error = "Error veryfying code."
        this.disableVerifyButton = false;
      });

    },
    initReCaptcha() {
      //setTimeout(() => {
      let vm = this
      if (!window.recaptchaVerifier)
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'invisible',
          'callback': function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          'expired-callback': function () {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          }
        }, auth);
      //
      this.appVerifier = window.recaptchaVerifier
      window.recaptchaVerifier.render();
      //}, 1000)
    },

  }
};
</script>

<style>
#recaptcha-container {
  display: none;
}
</style>

<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="partner-section">
                    <div class="divider"></div>
                    <p class="partner-text">
                        {{ $t('partnersText') }}
                    </p>
                    <div class="logo-container">
                        <img src="@/assets/img/partners/mint.png" alt="Ministry of Innovation and Technology Logo" class="partner-logo">
                        <img src="@/assets/img/partners/mcf.png" alt="Mastercard Foundation Logo" class="partner-logo">
                        <img src="@/assets/img/partners/ice.png" alt="Iceaddis Logo" class="partner-logo">
                    </div>
                    <div class="divider"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PartnerSection',
};
</script>

<style scoped>
.partner-section {
    text-align: left;
    padding: 20px 0;
    color: #b0b0b0;
    /* Light grey color */
}

.partner-text {
    margin-bottom: 20px;
    font-size: 10px;
}

.logo-container {
    display: flex;
    gap: 20px;
}

.partner-logo {
    width: auto;
    height: 50px;
}

.divider {
    height: 1px;
    background-color: #ebebeb;
    /* Light grey color */
    margin: 20px 0;
}
</style>
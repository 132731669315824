<template>
	<!-- Main Wrapper -->
	<div class="main-wrapper">

		<layouts2></layouts2>


		<!--Dashbord Student -->
		<div class="page-content">
			<div class="container">
				<div class="row">

					<studentsidebar :fullName="fullName" :profilePictureUrl="profilePictureUrl"></studentsidebar>

					<!-- Profile Details -->
					<div class="col-xl-9 col-md-8">
						<b-alert v-model="isRegisterError" variant="danger" dismissible>{{ this.message
							}}</b-alert>

						<b-alert v-model="showSuccessDialog" variant="success" dismissible>{{ this.message
							}}</b-alert>
						<div v-if="uploading" class="progress-bar-container">
							<div :style="{ 'width': progress + '%' }" class="progress-bar"></div>
							<div :style="{ 'width': progress + '%' }" class="progress-bar"></div>
						</div>
						<div class="settings-widget profile-details">
							<div class="settings-menu p-0">
								<div class="profile-heading">
									<h3>{{ $t('socialProfilesTitle') }}</h3>
									<p>{{ $t('socialProfilesDescription') }}</p>
								</div>
								<div class="checkout-form personal-address">
									<form @submit.prevent="handleSubmit" class="register">
										<div class="row">
											<div class="col-lg-12">
												<div class="form-group">
													<label class="form-control-label">{{ $t('xLabel') }}</label>
													<input type="text" v-model="x" class="form-control"
														:placeholder="$t('xPlaceholder')">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group">
													<label class="form-control-label">{{ $t('facebookLabel') }}</label>
													<input type="text" v-model="facebook" class="form-control"
														:placeholder="$t('facebookPlaceholder')">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group">
													<label class="form-control-label">{{ $t('instagramLabel') }}</label>
													<input type="text" v-model="instagram" class="form-control"
														:placeholder="$t('instagramPlaceholder')">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group">
													<label class="form-control-label">{{ $t('linkedinLabel') }}</label>
													<input type="text" v-model="linkedin" class="form-control"
														:placeholder="$t('linkedinPlaceholder')">
												</div>
											</div>
											<!--div class="col-lg-12">
							<div class="form-group">
							  <label class="form-control-label">{{ $t('youtubeLabel') }}</label>
							  <input type="text" class="form-control" :placeholder="$t('youtubePlaceholder')">
							</div>
						  </div-->
											<div class="update-profile save-social">
												<button type="submit" class="btn btn-primary">{{
													$t('saveSocialProfileButton') }}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<!-- Profile Details -->

				</div>
			</div>
		</div>
		<!-- /Dashbord Student -->
		<layouts1></layouts1>

	</div>
	<!-- /Main Wrapper -->
</template>


<script>
import Vue from "vue";
import axios from "axios"
import { useVuelidate } from '@vuelidate/core'
import {
	required,
	minLength,
	sameAs,
	maxLength,
	numeric,
	email
} from '@vuelidate/validators'
export default {
	data() {
		return {
			showPassword: true,
			password: "",
			validationError: 0,
			isPasswordValid: false,
			strength: "",

			email: '',

			isLoggedIn: false,
			firstName: '',
			fatherName: '',
			selectedImage: null,
			apiResponse: null,

			showPassword: false,
			validationError: 0,
			strength: "",
			errors: {
				password: "",
			},
			isLoading: false,
			error: '',


			regError: null,
			tryingToRegister: false,
			isRegisterError: false,

			submit: false,
			submitted: false,
			formsubmit: false,
			typesubmit: false,

			showSuccessDialog: false,

			message: "",
			studentId: "",
			uploading: false,

			name: '',
			fathersName: '',
			grandFathersName: '',

			fullName: '',
			profilePictureUrl: '',
			user: null,
			region: '',

			showModal: false,
			progress: 0,

			x: '',
			facebook: '',
			instagram: '',
			linkedin: ''
		};
	},
	setup: () => ({ v$: useVuelidate() }),
	validations() {
		return {
			email: { required, email },
		}
	},
	mounted() {
		// Call the validatePassword method here
		this.validatePassword(this.password);
		this.storedPhoneNumber = localStorage.getItem("phoneNumber");
		this.storedStudentId = localStorage.getItem("studentId");
		if (this.storedPhoneNumber)
			if (this.storedPhoneNumber.length > 0)
				this.isLoggedIn = true;

		// Retrieve the user data from localStorage and parse it
		const userData = localStorage.getItem('userData');

		if (userData) {
			this.user = JSON.parse(userData);

			this.fullName = this.user.fullName;
			if (this.user.name)
				this.name = this.user.name
			if (this.user.fathersName)
				this.fathersName = this.user.fathersName
			if (this.user.grandFathersName)
				this.grandFathersName = this.user.grandFathersName

			if (this.user.profilePicture) {
				if (this.user.profilePicture.length > 0)
					this.profilePictureUrl = this.user.profilePicture;
				else
					this.profilePictureUrl = 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e'
			}
			else
				this.profilePictureUrl = 'https://firebasestorage.googleapis.com/v0/b/mager-a0c81.appspot.com/o/profilePictures%2Fuser11.jpg?alt=media&token=107a4e90-61aa-43db-87cd-1a62758b854e'

			if (this.user.region)
				this.selectedCountry = this.user.region;
			if (this.user.email)
				this.email = this.user.email;
			if (this.user.x)
				this.x = this.user.x
			if (this.user.facebook)
				this.facebook = this.user.facebook
			if (this.user.instagram)
				this.instagram = this.user.instagram
			if (this.user.linkedin)
				this.linkedin = this.user.linkedin
		}
		//console.log('>>>>>>> fullName: ' + this.user.fullName);
		if (this.name.length == 0)
			this.splitName()

		this.progress = 0;
		this.scrollToTop()
		//window.addEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll() {
			const scroll = window.pageYOffset;

			if (scroll >= 100) {
				this.isSticky = true;
			} else {
				this.isSticky = false;
			}
		},
		async handleSubmit(e) {
			this.submitted = true;
			const isFormCorrect = await this.v$.$validate()
			if (!isFormCorrect) {
				//alert('Error')
				return
			}
			else
				this.updateProfile()
		},
		generateRandomNumber() {
			// Generate a random integer not greater than 50
			return Math.floor(Math.random() * 50); // 0 to 49 (inclusive)
		},
		showMessageDialog() {
			this.progress = 100;
			setTimeout(() => {
				this.uploading = false;
				this.showSuccessDialog = !this.isRegisterError
			}
				, 1000);
		},
		scrollToTop() {
			// Scroll to the top of the page
			window.scrollTo({
				top: 0,
				behavior: 'smooth', // Optional: Adds a smooth scrolling effect
			});
		},
		handlePasswordChange() {
			const newPassword = this.password;
			this.validatePassword(newPassword);
		},
		splitName() {
			const names = this.fullName.split(' ');
			this.name = names[0] || '';
			this.fathersName = names.slice(1, -1).join(' ').trim() || '';
			this.grandFathersName = names[names.length - 1] || '';
			if (this.fathersName.length == 0) {
				this.fathersName = this.grandFathersName;
				this.grandFathersName = '';
			}
		},
		validatePassword(value) {
			this.validationError = 0;
			if (!value) {
				this.validationError = 0;
			} else if (value.length < 8) {
				this.validationError = 2;
			} else if (!/[a-zA-Z]/.test(value) && !/[0-9]/.test(value)) {
				this.validationError = 3;
			} else if (!/[!@#$%^&*()]/.test(value)) {
				this.validationError = 4;
			} else {
				this.validationError = 5;
			}
			this.strength = this.strengthColor(this.validationError);
		},
		strengthColor(count) {
			if (count < 1) return "";
			if (count < 2) return "poor";
			if (count < 3) return "weak";
			if (count < 4) return "strong";
			return "heavy";
		},
		hasNumber(value) {
			return /[0-9]/.test(value);
		},
		hasMixed(value) {
			return /[a-z]/.test(value) && /[A-Z]/.test(value);
		},
		hasSpecial(value) {
			return /[!#@$%^&*)(+=._-]/.test(value);
		},
		strengthIndicator(value) {
			let strengths = 0;

			if (value.length >= 8) strengths = 1;
			if (this.hasNumber(value) && value.length >= 8) strengths = 2;
			if (this.hasSpecial(value) && value.length >= 8 && this.hasNumber(value))
				strengths = 3;
			if (
				this.hasMixed(value) &&
				this.hasSpecial(value) &&
				value.length >= 8 &&
				this.hasNumber(value)
			)
				strengths = 3;
			return strengths;
		},

		async updateProfile() {
			this.uploading = true;
			this.progress = this.generateRandomNumber();
			this.scrollToTop();
			try {
				await axios.put('/api/users/' + this.storedStudentId + '/update-social-profile', {
					x: this.x,
					facebook: this.facebook,
					instagram: this.instagram,
					linkedin: this.linkedin,
					onUploadProgress: (progressEvent) => {
						// Update the upload progress value
						this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
					},
					onDownloadProgress: (progressEvent) => {
						// Update the upload progress value
						this.progress += Math.round((progressEvent.loaded / progressEvent.total) * 50);
					},
				}).then((response) => {
					//this.error=response.data.error;

					this.isLoading = false;
					console.log(response.data.message);
					this.message = response.data.message;
					this.isRegisterError = response.data.error;
					this.tryingToRegister = false;
					this.showMessageDialog();


					//get user data
					localStorage.setItem('userData', JSON.stringify(response.data));
					//console.log('>>>>>>>>>>>> User Data: ' + JSON.stringify(response.data))
				})

			} catch (error) {
				console.log("There is an error: " + error);
				this.message = 'Error updating social profile. Please try again.';
				this.isLoading = false;
				this.tryingToRegister = false;
				this.isRegisterError = true;
				this.uploading = false;
			}
		},
	},

};
</script>
<style>
/* Style the modal to make it visually appealing */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.course-group .profile-share-new .modal-content {
	background: transparent;
	color: #666666;
	border: 1px solid orangered;
	margin-right: 10px;
	margin-top: 10px;
	border-radius: 0;
	padding: 10px 10px;
	font-weight: 100;
	border-radius: 5px;
	font-size: 12px;
}

.course-group .profile-share-new .modal-content .btn-success {
	background: transparent;
	color: #666666;
	border: 1px solid #666666;
	margin-right: 10px;
	border-radius: 0;
	padding: 7px 22px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 12px;
}

.course-group .profile-share-new .modal-content .btn-success:hover {
	background: orangered;
	color: #FFFFFF;
	border: 1px solid #FFFFFF;
}

.course-group .profile-share-new .modal-content .btn-cancel {
	background: transparent;
	color: #666666;
	border: 1px solid #666666;
	margin-right: 10px;
	border-radius: 0;
	padding: 7px 22px;
	font-weight: 500;
	border-radius: 5px;
	font-size: 12px;
}

.course-group .profile-share-new .modal-content .btn-cancel:hover {
	background: #666666;
	color: #FFFFFF;
	border: 1px solid #666666;
}

/* Style for the progress bar container */
.progress-bar-container {
	width: 100%;
	height: 10px;
	background-color: #eee;
	border-radius: 3px;
	margin-bottom: 10px;
	overflow: hidden;
}

/* Style for the progress bar */
.progress-bar-container .progress-bar {
	height: 100%;
	background-color: #4caf50;
	/* Green color */
	transition: width 0.3s ease;
	/* Add a smooth transition effect */
	border-radius: 3px;
}
</style>

<template>
    <!-- Breadcrumb -->
    <div class="breadcrumb-bar">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="breadcrumb-list">
                        <nav aria-label="breadcrumb" class="page-breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">{{ $t('homeLink') }}</router-link></li>
                                <li class="breadcrumb-item"><router-link to="/course-grid">{{ $t('coursesLink')
                                        }}</router-link></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ courseTitle }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Breadcrumb -->
</template>

<script>
export default {
    props: {
        courseTitle: {
            type: String,
            required: true,
        },

        // Add more props if needed
    },
};
</script>

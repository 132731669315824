<template>
  <div class="toggle-container">
    <input type="checkbox" id="language-toggle" @change="toggleLanguage" :checked="isAmharic" />
    <label for="language-toggle" class="toggle-label">
      <span class="toggle-item home" @click="goHome">
        <img src="@/assets/img/icon/home.svg" alt="Home" class="home-icon" />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAmharic: this.$i18n.locale === 'am'
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    },
    toggleLanguage(event) {
      this.isAmharic = event.target.checked;
      this.$i18n.locale = this.isAmharic ? 'am' : 'en';
    },
    setLanguage(locale) {
      this.isAmharic = locale === 'am';
      this.$i18n.locale = locale;
    }
  }
}
</script>

<style>
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

#language-toggle {
  display: none;
}

.toggle-label {
  position: relative;
  width: 120px;
  height: 34px;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.toggle-item {
  font-size: 14px;
  color: #fff;
  z-index: 1;
  width: 40px;
  text-align: center;
  line-height: 34px; /* Center the text vertically */
  transition: color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-item.home {
  padding: 5px;
  background-color: transparent; /* Remove background */
  border-right: 1px solid #ccc; /* Maintain border */
  margin-right: 10px; /* Add right margin */
}

.toggle-item.left {
  border-radius: 10px 0 0 10px;
}

.toggle-item.right {
  border-radius: 0 10px 10px 0;
}

.toggle-item.active {
  background-color: #007bff;
  color: #fff;
}

.toggle-item:not(.active) {
  background-color: transparent;
  color: #000;
}

.home-icon {
  width: 20px;
  height: 20px;
}
</style>
